import React, {useEffect, useState} from "react";
import $ from "jquery";
import {Link} from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router';
import { toAbsoluteUrl } from "../../../_helpers";
import SVG from "react-inlinesvg";
import alertify from "alertifyjs";
import { useSelector } from "react-redux";
import { LoaderScreen } from '../../modules/Loader';
import { getOperators, removeOperator } from "../../../app/sagas/userManagement";

export function PartnerList(props){
	const [loading, setLoading] = useState(true);
	const { userId } = useSelector((state) => state.Auth.user);
	const [partners, setPartners] = useState([]);
	const [partner, setPartner] = useState(null);
	const [show, setShow] = useState(false);
	const history = useHistory()
	const [offset, setOffset] = useState(0);
	const [pageLimit, setPageLimit] = useState(100);
	const [totalPages, setTotalPages] = useState(0);
	const [deleting, setDeleting] = useState(false);

	const setCurrentPartner = (partner) => {
	    setPartner(partner);
	    setShow(true);
	};
	useEffect(() => {
		const fetchData = async () => {
			try {
				const apiResponse = await getOperators(offset, pageLimit);
				const { data = [], success } = apiResponse?.data;
				if (success) {
					setPartners(data);
				}
			} catch (error) {
				alertify.error(error.message);
			}
		};
		fetchData();
	}, [offset, pageLimit]);
	useEffect(() => {
		if(partners.length > 0){
			$("#partner_table").DataTable({responsive: true, stateSave: true});
		}
		setLoading(false);
	}, [partners]);

	const deletePartner = async() => {
		try {
			setDeleting(true)
			const apiResponse = await removeOperator(partner, userId);
			const { success, errors = [] } = apiResponse?.data;
			if(success){
			  alertify.success("Record removed successfully");
			  history.go(0);
			} else{
			  alertify.error(errors[0].errorMsg);
			}
		} catch (error) {
			alertify.error(error.message);
		} finally {
			setShow(false);
			setDeleting(false);
		}
  	}

	return (
		loading ? <LoaderScreen />:
		<>
			<table id="partner_table" className="sharearide_datatable">
				<thead>
					<tr>
						<th>ID</th>
						<th>Name</th>
						<th>Image</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{partners.length > 0 ? (
						partners.map((partner, index) => (
						<tr key={partner.id}>
							<td>{partner.id}</td>
							<td>{partner.name}</td>
							<td>{partner.image ? <img width={60} src={new Buffer.from(partner.image).toString("ascii")} style={{borderRadius: '50%'}}/> : ''}</td>
							<td>
								<Link to={`/partner/edit/${partner.uuid}`} className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
									<span className="svg-icon svg-icon-md svg-icon-primary">
									<SVG
										src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
									/>
									</span>
								</Link>
								<a onClick={() => setCurrentPartner(partner.uuid)} key={index} className="btn btn-icon btn-light btn-hover-danger btn-sm">
									<span className="svg-icon svg-icon-md svg-icon-danger">
									<SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
									</span>
								</a>
							</td>		            
						</tr>
						))
					) : (
						<tr>
							<td colSpan={4}>No Partner</td>
						</tr>
					)}
				</tbody>
		  	</table>
			<Modal show={show} onHide={() => setShow(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Delete Partner</Modal.Title>
				</Modal.Header>
				<Modal.Body>Are you sure you want to Delete?</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShow(false)} disabled={ deleting }>
						Cancel
					</Button>
					<Button variant="primary" onClick={deletePartner} disabled={ deleting }>
						<span>Yes</span> {deleting && <span className="ml-2 spinner spinner-white"></span>}&nbsp;&nbsp;
					</Button>
				</Modal.Footer>
			</Modal>
	    </>
	)
}