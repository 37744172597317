import React, {  } from "react";
import {BookingList} from "./BookingList";

import { Card, CardBody, CardHeader,} from "../../../_metronic/_partials/controls";

export function Bookings() {

  return (
    <Card>
      <CardHeader title="Booking List">
        {/* <CardHeaderToolbar>
               
          <Link to="/booking/create" className="btn btn-primary">Add New</Link>
        </CardHeaderToolbar> */}
      </CardHeader>
      <CardBody>
        <div className="mt-5"><BookingList /> </div>
      </CardBody>
    </Card>

  );
}