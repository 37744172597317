import React, { useEffect, useState, useRef, useCallback } from "react";
import axios from "axios";
import Button from 'react-bootstrap/Button';
import $ from "jquery";
import { toAbsoluteUrl } from "../../../_helpers";
import alertify from "alertifyjs";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {API_URL, transaction, user, busManagement, city} from '../constants.js';
import { Card, CardBody, CardHeader, CardFooter} from "../../../_metronic/_partials/controls";
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import Nav from 'react-bootstrap/Nav';
import TabPane from 'react-bootstrap/TabPane';
import TabContent from 'react-bootstrap/TabContent';
import TabContainer from 'react-bootstrap/TabContainer';
import { useSelector } from "react-redux";
import { getBus } from "../../../app/sagas/busManagement";
import { days, alphabets } from '../constants';
import { LoaderScreen } from '../../modules/Loader';
import moment from 'moment';

export function BookingCreate(props) {

  const forceUpdate = useCallback(() => ({}), []);
  const { userId, role } = useSelector((state) => state.Auth.user);
  const [loading, setLoading] = useState(false);
  const ticketPrice = useRef();
  const paymentStatus = useRef();
  const usageStatus = useRef();
  const departureTimeRef = useRef();
  const arrivalTimeRef = useRef();
  const bookingDate = useRef();
  const primaryApplicant = useRef();
  const emailAddress = useRef();
  const cellNumber = useRef();  
  const routePrice = useRef();
  const selectedRoute = useRef();
  const selectBusInputRef = useRef();
  
  const [cities, setCities] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [availableBusView, setAvailableBusView] = useState([]);
  const [selectedSeats, setSelectedSeats] = useState([]);
  const [passengers, setPassengers] = useState([]);
  const [departure, setDeparture] = useState({});
  const [destination, setDestination] = useState({});
  const [departureTime, setDepartureTime] = useState('');  
  const [arrivalTime, setArrivalTime] = useState('');
  const [selectedRoutePrice, setSelectedRoutePrice] = useState('');
  const [selectedRouteName, setSelectedRoute] = useState({}); 
  const [fare, setFare] = useState(0);
  const [selectedBus, setSelectedBus] = useState({
    value: '',
    label: 'Select bus'
  });

  const setAvailableBusSeats = (data, allBookedSeats) =>{
    $("#bus-body tr").remove();
    const totalSeats = data.Bus.totalSeats;
    const seatingLayout = data.Bus.layout;
    const lastSeat = data.Bus.lastseat;
    let seatsPerRow = seatingLayout.split("/");
    seatsPerRow = parseInt(seatsPerRow[0]) + parseInt(seatsPerRow[1]);
    let emptyAt = seatingLayout.split("/");
    emptyAt = parseInt(emptyAt[0]) + 1;            
    let rowLength = seatsPerRow + 1;
    let seatRows = totalSeats / seatsPerRow;
    let seatingLayoutArr = [];
    let arr = [];

    for(let j = 1; j <= rowLength; j++){
      if(j === rowLength){
        arr.push({
          id: 'driver',
          value: 'driver'
        });
      }else{
        arr.push({
          id: 'empty',
          value: 'empty'
        });
      }
    }
    seatingLayoutArr.push(arr);
    
    for(let i = 1; i <= seatRows; i++){
      arr = [];
      let num = 1;
      for(let k = 1; k <= rowLength; k++){
        if(i === seatRows && lastSeat){
          arr.push({
            id: alphabets[i] + num,
            value: alphabets[i] + num
          });
          num++;
        }else{
          if(k === emptyAt){
            arr.push({
              id: 'empty',
              value: 'empty'
            });
          }else{
            arr.push({
              id: alphabets[i] + num,
              value: alphabets[i] + num
            });
            num++;
          }
        }                
      }
      seatingLayoutArr.push(arr);
    }

    let tr = document.createElement('TR');
    for(let x = 0; x < seatingLayoutArr.length; x++){
      if(x === 0){
        for(let n = 0; n < seatingLayoutArr[x].length; n++){
          if(seatingLayoutArr[x][n].id === 'empty'){
            let td = document.createElement("TD");
            tr.appendChild(td);
          }else{
            let td = document.createElement('TD');
            let img = document.createElement('IMG');
            img.src = toAbsoluteUrl("/media/svg/files/bus/steering-wheel.png");
            img.setAttribute('width', '50%');                    
            td.appendChild(img);
            tr.appendChild(td);
          }
        }      
        document.getElementById('bus-body').appendChild(tr);
      }else{
        let tr = document.createElement('TR');
        for(let h = 0; h < seatingLayoutArr[x].length; h++){              
          if(seatingLayoutArr[x][h].id === 'empty'){
            let td = document.createElement("TD");
            tr.appendChild(td);
          }else{            
            if(allBookedSeats.indexOf(seatingLayoutArr[x][h].id) > -1){
              let td = document.createElement('TD');
              td.setAttribute('id','td'+seatingLayoutArr[x][h].id);
              let img = document.createElement('IMG');
              img.src =toAbsoluteUrl("/media/svg/files/bus/seat-booked.png");
              img.setAttribute('id', seatingLayoutArr[x][h].id);
              img.setAttribute('width', '50%');
              td.appendChild(img);
              tr.appendChild(td);
            }else{
              let td = document.createElement('TD');
              td.setAttribute('id','td'+seatingLayoutArr[x][h].id);
              let img = document.createElement('IMG');
              img.src = toAbsoluteUrl("/media/svg/files/bus/seat-available.png");
              img.setAttribute('id', seatingLayoutArr[x][h].id);
              img.setAttribute('width', '50%');
              img.addEventListener('click', handleSeatSelection);
              td.appendChild(img);
              tr.appendChild(td);
            }
          }
        }
        document.getElementById('bus-body').appendChild(tr);
      }
    }
  }

  const busWithinTimeScope = (busTime) =>{
    let currentTime = busTime.split(":"),
    currentDateTime = moment(),
    currentDate = moment().format("YYYY-MM-DD"),
    bookedDate = bookingDate.current.value,
    busDateTime = moment(bookedDate).hours(currentTime[0]).minutes(currentTime[1]).seconds(0),
    proposedBookedDate = moment(bookedDate).format("YYYY-MM-DD");
    if(currentDate.valueOf() === proposedBookedDate.valueOf()){
      if(busDateTime.diff(currentDateTime, 'minutes') < 10){        
        return false;
      } else{
        return true;
      }
    } else{
      return true;
    }
  }

  const fetchSearchResults = (starting, ending, day) => {
    searchResults.splice(0, searchResults.length);
    setSearchResults([...searchResults]);
    setSelectedRoutePrice('');
    setSelectedRoute('');
    availableBusView.splice(0, availableBusView.length);
    setAvailableBusView([...availableBusView]);
    setSelectedBus(null)
    selectBusInputRef.current.select.clearValue();
    passengers.splice(0, passengers.length);
    setPassengers([...passengers]);
    $("#bus-body tr").remove();

    try{
      axios.get(`${API_URL}/v1/app/global/search/${day}/${starting}/${ending}`).then( (response) => {
        const { success, data } = response?.data;
        if(success){
          if(role === "admin"){
            axios.get(`${API_URL}${user}/${userId}`).then( (res) => {
              if(res?.data.success){
                localStorage.setItem("busOperator", res?.data.data.user.busOperator);
                for(let i = 0; i < data.length; i++){
                  if(busWithinTimeScope(data[i].DepartureTime)){
                    if(res?.data.data.user.busOperator === data[i].BusOperator){
                      availableBusView.push({
                        label: data[i].RegNo,
                        value: data[i].BusId
                      });
                    }
                  }
                }
                setAvailableBusView([...availableBusView]);
              }
            });	
          } else if(role === "agent"){
            axios.get(`${API_URL}${busManagement}/agent/${userId}`).then( (res) => {
              if(res?.data.success){
                for(let i = 0; i < data.length; i++){
                  if(busWithinTimeScope(data[i].DepartureTime)){
                    if(res?.data?.data?.associatedBuses.map(function(e) { return e.bus.regNo }).indexOf(data[i].RegNo) !== -1){
                      availableBusView.push({
                        label: data[i].RegNo,
                        value: data[i].BusId
                      });
                    }
                  }
                }
                setAvailableBusView([...availableBusView]);
              }
            });
          } else {
            for(let i = 0; i < data.length; i++){
              if(busWithinTimeScope(data[i].DepartureTime)){
                availableBusView.push({
                  label: data[i].RegNo,
                  value: data[i].BusId
                });
              }
            }            
            setAvailableBusView([...availableBusView]);
          }
          setSearchResults([...data]);
          setSelectedRoute({
            value: data[0].BusSubRouteId,
            label: data[0].RouteName,
            departureStop: data[0].departureStop,
            destinationStop: data[0].destinationStop,
            isMain: data[0].isMain
          });
        } else{
          alertify.error(`No available support from ${departure.label} to ${destination.label} on the ${bookingDate.current.value} `);
        }
      });
    } catch (error) {
      alertify.error(error.message);
    }      
  }

  const handleDateChange = () => {
    if(bookingDate.current.value !== ''){
      const date = bookingDate.current.value;
      const now = new Date(date);
      let day = days[ now.getDay() -1 ];
      if(departure !== undefined && departure !== null){
        if(departure.value !== undefined && departure.value !== ''){
          if(destination !== undefined && destination !== null){
            if(destination.value !== undefined && destination.value !== ''){
              fetchSearchResults(departure.value, destination.value, day.value);
            }
          }
        }
      }      
    }
    forceUpdate();
  }

  const handleDepartureChange = (e) => {
    if(e !== undefined && e !== null){
      if(destination !== undefined && destination !== null){
        if(e.value === destination.value){
          alertify.error("Departure can not be the same as destination");
        } else{
          setDeparture(e);
          if(bookingDate.current.value !== ''){
            const date = bookingDate.current.value;
            const now = new Date(date);
            let day = days[ now.getDay() -1 ];
            if(e.value !== undefined && e.value !== null){
              if(e.value !== undefined && e.value !== ''){
                if(destination.value !== undefined && destination.value !== ''){
                  fetchSearchResults(e.value, destination.value, day.value);
                }
              }
            }      
          }
        }
      } else{
        setDeparture(e);
      }
    }
    forceUpdate();
  }

  const handleDestinationChange = (e) => {    
    if(e !== undefined && e !== null){
      if(departure !== undefined && departure !== null){
        if(e.value === departure.value){
          alertify.error("Destination can not be the same as departure");
        } else{
          setDestination(e);
          if(bookingDate.current.value !== ''){
            const date = bookingDate.current.value;
            const now = new Date(date);
            let day = days[ now.getDay() -1 ];
            if(departure.value !== undefined && departure.value !== ''){
              if(e.value !== undefined && e.value !== null){
                if(e.value !== ''){
                  fetchSearchResults(departure.value, e.value, day.value);
                }
              }
            }     
          } 
        }
      } else{
        setDestination(e);
      }
    }    
    forceUpdate();
  }

  const handleBusChange = async(e) => {
    if(e !== undefined && e !== null){
      if(e.value !== undefined){
        if(e.value !== ''){
          setSelectedBus({
            value: e.value,
            label: e.label
          });
          /**
           * Get bus details                   
           */
          const apiResponse = await getBus( e.value );
          const { success, data = {}, errors = [] } = apiResponse?.data;
          if(success){
            const busInfo = searchResults.filter(result => result.BusId === e.value);
            setDepartureTime(busInfo[0].DepartureTime);
            let hours = Math.floor(parseInt(busInfo[0]?.Time) / 60) || 0;
            let minutes = Math.floor(parseInt(busInfo[0]?.Time) % 60) || 0;
            const departureTimeSplit =  busInfo[0].DepartureTime.split(":");
            minutes = parseInt(minutes) + parseInt(departureTimeSplit[1]);
            hours = Math.floor(minutes / 60) + (parseInt(hours) + parseInt(departureTimeSplit[0]));
            minutes = Math.floor(minutes / 60) > 0 ? Math.floor(parseInt(minutes) % 60) : minutes;
            minutes = minutes.toString().length > 1 ? minutes : "0" + minutes.toString();
            setArrivalTime(`${hours}:${minutes}`);
            setSelectedRoutePrice(busInfo[0].Price);
            let allBookedSeats = [];
            let config = {
              method: 'get',
              url: `${API_URL}${transaction}/bookings/${e.value}/all/${bookingDate.current.value}/date`,
              headers: { }
            };
            axios(config).then(function (res) {
			        if(res?.data.success){
                const result = res.data?.data;
                for(let i = 0; i < result.length; i++){
                  if((String(selectedRouteName.isMain) == "true")){
                    if(result[i].status === "active"){
                      let seats = result[i].seats.replace(/'/g, '');
                      seats = seats.split(",");
                      allBookedSeats.push.apply(allBookedSeats, seats);
                    }
                  } else{
                    if(parseInt(selectedRouteName.departureStop) < parseInt(result[i].arrivalSequenceNumber)){
                      if(parseInt(selectedRouteName.destinationStop) > parseInt(result[i].departureSequenceNumber)){
                        if(result[i].status === "active"){
                          let seats = result[i].seats.replace(/'/g, '');
                          seats = seats.split(",");
                          allBookedSeats.push.apply(allBookedSeats, seats);
                        }
                      }                      
                    }
                  }
                }
                setAvailableBusSeats(data, allBookedSeats);
              } else{
                setAvailableBusSeats(data, allBookedSeats);                
              }
            }).catch(()=>{
              setAvailableBusSeats(data, allBookedSeats);
            })
          }
        }
      }
    }
    forceUpdate();
  }

  const handleSeatSelection = (e) =>{
    let seats_el = document.getElementById('seats');
    seats_el.style.border = "1px solid #ddd";
    if(selectedSeats.indexOf(e.target.id) > -1){
      for( let i = 0; i < selectedSeats.length; i++){
        if ( selectedSeats[i] === e.target.id){
          selectedSeats.splice(i, 1);
          i--;
        }
      }
      selectedSeats.sort();
      setSelectedSeats([...selectedSeats]);
      document.getElementById(e.target.id).src = toAbsoluteUrl("/media/svg/files/bus/seat-available.png");
      setFare((routePrice?.current?.value * selectedSeats.length))
    }else{
      if(selectedSeats.length < 4){
        selectedSeats.push(e.target.id);
        selectedSeats.sort();
        setSelectedSeats([...selectedSeats]); 
        document.getElementById(e.target.id).src = toAbsoluteUrl("/media/svg/files/bus/seat-selected.png");
        setFare((routePrice?.current?.value * selectedSeats.length))
      } else{
        alertify.error("Maximum seats of 4 reached");
        return;
      }
    }    
    let _newPassengers = {};
    let _passengers = passengers;
    let _blankPassenger = {
      "id": `${e.target.id}`,
      "uuid": null,
      "names": "",
      "seatNumber": `${e.target.id}`,
      "status": "active"
    };
    let _selected_seats = selectedSeats;

    _selected_seats.map((idx)=>{
      if(!Object.keys(_passengers).includes(idx)){
        Object.assign(_passengers, {[idx]: _blankPassenger});
      }      
    });

    Object.keys(_passengers).map((idx)=>{
      if(_selected_seats.includes(idx)){
        Object.assign(_newPassengers, {[idx]: _passengers[idx]});
      }
    });

    let _tempPassengers = Object.entries(_newPassengers).sort(([,a],[,b]) => a-b).reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    setPassengers(_tempPassengers);    
    forceUpdate();
  }

  const handlePassengerChange = (idx) => (e) => {
    const _passengers = passengers;
    if(!_passengers.hasOwnProperty(idx)) return;
    _passengers[idx].names = e.target.value;
    setPassengers(_passengers);
    forceUpdate();
  }

  const handleSubmit = ()=>{ 
    if(primaryApplicant.current.value === "" || cellNumber.current.value === "" ||
      bookingDate.current.value === "" || ticketPrice.current.value === "" || selectedSeats === "" || paymentStatus.current.value === "" || 
      usageStatus.current.value === "" || selectedBus.value === "" || selectedRouteName.value === "" || passengers.length < 1){
        alertify.warning("Please provide all mandatory values first");
    } else{
      const bookedPassengers = []
      Object.keys(passengers).forEach(key => {
        bookedPassengers.push({
          id: null,
          uuid: null,
          names: passengers[key].names,
          seatNumber: passengers[key].seatNumber,
          status: usageStatus.current.value
        });
      });
      
      const data = JSON.stringify({
        "primaryUser":{
          "names": primaryApplicant.current.value,
          "email": emailAddress.current.value,
          "phone": cellNumber.current.value, 
        },
        "bookings": {
          "id": null,
          "uuid": null,
          "bookingDate": bookingDate.current.value,
          "ticketPrice": ticketPrice.current.value,
          "fare": routePrice.current.value,
          "seats": "'" + selectedSeats + "'",
          "departureSequenceNumber": selectedRouteName.departureStop,
          "arrivalSequenceNumber": selectedRouteName.destinationStop,
          "isPaid": paymentStatus.current.value,
          "cancelledAt": null,
          "status": usageStatus.current.value,
          "user": userId,
          "busId": selectedBus.value,
          "busSubRouteId": selectedRouteName.value
        },
        "passengers": bookedPassengers
      });

      const config = {
        method: 'post',
        url: `${API_URL}${transaction}/bookings/`,
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };

      axios(config).then(function (response) {
        const { success, errors = [], data, message } = response?.data;
        if(success){
          alertify.success(message);
          window.location.reload();
        } else{
          alertify.error(errors[0]?.errorMsg || message);
        }
      }).catch(function (error) {
        alertify.error(error.message);
      });
    }    
  }

  useEffect( () => {
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if(dd < 10){
      dd = '0' + dd;
    } 
    if(mm < 10){
      mm = '0' + mm;
    } 
    today = yyyy + '-' + mm + '-' + dd;
    document.getElementById("bookingDate").setAttribute("min", today);    
    try{
      setLoading(true);
      axios.get(`${API_URL}${city}/all/0/5000`).then( (response) => {
        const { success, data } = response?.data;
        if(success){
          for(let i = 0; i < data.length; i++){
            cities.push({
              label: data[i].name,
              value: data[i].uuid
            });
          }
          setCities([...cities]);
        }
      });
    } catch (error) {
      alertify.error(error.message);
    } finally{
      setLoading(false);      
    }
  }, []);
	
  return ( 
    loading ? <LoaderScreen /> : 
    <Row>
      <Col md={12}>
        <Card>
          <CardHeader title="Booking Detail"/>
          <CardBody>
            <TabContainer id="left-tabs-example" defaultActiveKey="bookings_details">
              <Row>
                <Col sm={3}>
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item> <Nav.Link eventKey="bookings_details">Booking Information</Nav.Link></Nav.Item>
                    <Nav.Item> <Nav.Link eventKey="travel_information">Passenger Details</Nav.Link> </Nav.Item>
                    <Nav.Item> <Nav.Link eventKey="passenger_information">Other</Nav.Link> </Nav.Item>                    
                  </Nav>
                </Col>
                <Col sm={9}>
                  <TabContent>
                    <TabPane eventKey="bookings_details">
                      <Form.Row>
                        <Form.Group as={Col}>
                          <Form.Label>Primary Applicant <span style={{color: 'red'}}>*</span></Form.Label>
                          <Form.Control ref={primaryApplicant} placeholder="Enter full names of primary applicant" />
                        </Form.Group>
                        <Form.Group as={Col}>
                          <Form.Label>Email address (optional)</Form.Label>
                          <Form.Control ref={emailAddress} placeholder="Enter email address of primary applicant" />
                        </Form.Group>
                        <Form.Group as={Col}>
                          <Form.Label>Cell number (+ country code) <span style={{color: 'red'}}>*</span></Form.Label>
                          <Form.Control ref={cellNumber} placeholder="Enter cell number of primary applicant (include country code)" />
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col}>
                          <Form.Label>Booking Date <span style={{color: 'red'}}>*</span></Form.Label>
                          <Form.Control id="bookingDate" type="date" ref={bookingDate} onChange={handleDateChange} placeholder="Enter cell number of primary applicant (include country code)" />
                        </Form.Group>
                        <Form.Group as={Col}>
                          <Form.Label>Departure <span style={{color: 'red'}}>*</span></Form.Label>
                          <Select isClearable isSearchable name="departures" options={cities} onChange={handleDepartureChange} />                  
                        </Form.Group>
                        <Form.Group as={Col}>
                          <Form.Label>Destination <span style={{color: 'red'}}>*</span></Form.Label>
                          <Select isClearable isSearchable name="destinations" options={cities} onChange={handleDestinationChange} />
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col}>
                          <Form.Label>Route <span style={{color: 'red'}}>*</span></Form.Label>
                          <Form.Control ref={selectedRoute} value={selectedRouteName.label} readOnly disabled style={{background: '#D3D3D3'}}/>
                        </Form.Group>
                        <Form.Group as={Col}>
                          <Form.Label>Available Buses <span style={{color: 'red'}}>*</span></Form.Label>
                          <Select ref={selectBusInputRef} isClearable isSearchable name="buses" options={availableBusView} onChange={handleBusChange} value={selectedBus?.id} />
                        </Form.Group>
                        <Form.Group as={Col}>
                          <Form.Label>Applicable Fee</Form.Label>
                          <Form.Control ref={routePrice} value={selectedRoutePrice} readOnly disabled style={{background: '#D3D3D3'}}/>
                        </Form.Group>
                      </Form.Row>
                    </TabPane>
                    <TabPane eventKey="travel_information">
                      <Row>
                        <Col md={12}>
                          <Row>
                            <Col style={{marginTop: '30px'}} md={6}>
                              <Row>
                                <Col sm={4}>
                                  <div>
                                    <span><img style={{width: '30px'}} src={toAbsoluteUrl("/media/svg/files/bus/seat-booked.png")}/></span><br />
                                    <span style={{color: '#9B9B9B'}}> Booked</span>
                                  </div>
                                </Col>
                                <Col sm={4}>
                                  <div>
                                    <span><img style={{width: '30px'}} src={toAbsoluteUrl("/media/svg/files/bus/seat-available.png")} /></span>
                                    <span style={{color: '#000000'}}> Available</span>
                                  </div>
                                </Col>
                                <Col sm={4}>
                                  <div>
                                    <span><img style={{width: '30px'}} src={toAbsoluteUrl("/media/svg/files/bus/seat-selected.png")} /></span>
                                    <span style={{color: '#02928b'}}> Selected</span>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                            <Col md={6}>
                              <Form.Group as={Col}>
                                <Form.Label>Seat Numbers</Form.Label>
                                <Form.Control id="seats" name="cbSeats" className="form-control" disabled value={selectedSeats} readOnly style={{background: '#D3D3D3'}}/>
                              </Form.Group>
                            </Col>                                        
                          </Row>
                        </Col>
                        <Col md={6}>
                          <div style={{marginTop: '14px', border: '2px solid #999', padding: '10px', borderRadius: '15px'}}>
                            <table className="bus-layout">
                              <tbody id="bus-body"></tbody>
                            </table>
                          </div>
                        </Col>
                        <Col md={6} style={{height: '320px', overflowY: 'scroll'}}>
                          <div className="py-3">
                            {Object.keys(passengers).map((idx) => (<div className="form-group">
                              <div className="box-field input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text" style={{background:"#02928b",color:"#fff"}}>{ idx }</span>
                                </div>
                                <Form.Group as={Col}>
                                  <Form.Label>Passenger Details <span style={{color: 'red'}}>*</span></Form.Label>
                                  <Form.Control type="text" key={ 'passengers_' + idx } id={ 'passenger_' + idx } required name={ 'passengers['+ idx +'][name]' } className="form-control" onChange={handlePassengerChange(idx)} value={passengers[idx].name} placeholder="Enter Passenger Name"/>
                                </Form.Group>
                              </div>
                            </div>))}
                          </div>
                        </Col>                      
                      </Row>                      
                    </TabPane>
                    <TabPane eventKey="passenger_information">
                      <Form.Row>
                        <Form.Group as={Col}>
                          <Form.Label>Departure Time</Form.Label>
                          <Form.Control ref={departureTimeRef} value={departureTime} className="form-control" disabled readOnly style={{background: '#D3D3D3'}}/>
                        </Form.Group>
                        <Form.Group as={Col}>
                          <Form.Label>Arrival Time</Form.Label>
                          <Form.Control ref={arrivalTimeRef} value={arrivalTime} className="form-control" disabled readOnly style={{background: '#D3D3D3'}}/>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col}>
                          <Form.Label>Booking Fare</Form.Label>
                          <Form.Control ref={ticketPrice} value={fare} className="form-control" disabled readOnly style={{background: '#D3D3D3'}}/>
                        </Form.Group>
                        <Form.Group as={Col}>
                          <Form.Label>Payment Status <span style={{color: 'red'}}>*</span></Form.Label>
                          <Form.Control as="select" ref={paymentStatus} margin="dense">
                            <option value="Yes">Paid</option>
                            <option value="No">Pending</option>
                          </Form.Control>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col}>
                          <Form.Label>Usage Status <span style={{color: 'red'}}>*</span></Form.Label>
                          <Form.Control as="select" ref={usageStatus} margin="dense">
                            <option value="active">Active</option>
                            <option value="used">Used</option>
                            <option value="cancelled">Cancelled</option>
                          </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col}></Form.Group>
                      </Form.Row>
                    </TabPane>
                  </TabContent>
                </Col>
              </Row>
            </TabContainer>          
          </CardBody>
          <CardFooter>
            <Button variant="primary" className="float-right" onClick={handleSubmit}>Save</Button>
          </CardFooter>
        </Card>
      </Col>
    </Row>
  );
}