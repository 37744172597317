import React from "react";
import Spinner from 'react-bootstrap/Spinner'

export function LoaderScreen() {
  return (
    <div style={{opacity: 0.8, backgroundColor:'#ff8476', position: 'fixed', width:'100%', height:'100%', top:'0px', left:'0px', zIndex:1000}}>
      <div style={{ position: 'absolute', left: '60%', top: '50%', transform: 'translate(-60%, -50%)' }} >
        <Spinner animation="grow" variant='primary' />
      </div>
    </div>
  );
}
