import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import alertify from "alertifyjs";
import { Card, CardBody, CardHeader, } from "../../../_metronic/_partials/controls";
import {API_URL, busManagement} from '../constants.js';
import { handleResponse } from '../../../_helpers';
import { useSelector } from "react-redux";
import { LoaderScreen } from '../../modules/Loader';
import { getOperator, updateOperator } from "../../../app/sagas/userManagement";

export function EditPartner(props) {

  const [loading, setLoading] = useState(false);
	const { userId } = useSelector((state) => state.Auth.user);
	const initialState = {
		partner: '',
		status: '',
	};

	const [partner, setPartner] = useState({});
	const [fields, setFields] = useState(initialState);
  const name = useRef();
  const contact = useRef();
  const status = useRef();
  const [image, setImage] = useState('');
  const id = props.match.params.id;

  useEffect(() => {    
    const fetchData = async () => {
      try {
        const apiResponse = await getOperator( id );
        const { success, data = {}, errors = [] } = apiResponse?.data;
        if(success){
          setFields({status: data.status});
          setPartner(data);
        const img = data.image != null ? new Buffer.from(data.image).toString("ascii") : null;
        setImage(img);
        } else{
          alertify.error(errors[0].errorMsg);
        }
      } catch (error) {
        alertify.error(error.message);
      } finally {
        setLoading(false);
      }
		};
		fetchData();
	}, []);
	
	const handleChange = (e) => {
		setFields({status: e.target.value});
  }
  
  const handleImage = (e) =>{
    var reader = new FileReader(); 
    reader.onload = (e) => {
      setImage(e.target.result);
      setPartner((currentState)=>({
        ...currentState,
        image: e.target.result
      }));
    };
    reader.readAsDataURL(e.target.files[0]);
  }

  const editPartnerHandler = async(e) => {
  	e.preventDefault();
    setLoading(true);
    try {
      const apiResponse = await updateOperator( {
        "operator":{
          "id": partner.id,
          "uuid": partner.uuid,
          "name": name.current.value,
          "contact": contact.current.value,
          "image": image,
          "order": partner.order,
          "status": status.current.value,
          "createdBy": partner.createdBy,
          "updatedBy": partner.updatedBy,
          "deleted": partner.deleted
        }
      }, userId);
      const { success, errors = [] } = apiResponse?.data;
      if(success){
        alertify.success("Record updated successfully");
        props.history.push("/partner/list");
      } else{
        alertify.error(errors[0].errorMsg);
      }
    } catch (error) {
      alertify.error(error.message);
    } finally {
      setLoading(false);
    }
  }
  return (
    loading ? <LoaderScreen />:
    <Row>
      <Col md={12}>
        <Card>
          <CardHeader title="Edit Partner"/>
          <CardBody>            
            <div className="mt-5">
              <Form onSubmit={editPartnerHandler}>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Name</Form.Label>
                    <Form.Control ref={name} defaultValue={partner.name} placeholder="Enter name" />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Contact</Form.Label>
                    <Form.Control ref={contact} defaultValue={partner.contact} placeholder="Enter contact number" />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Image</Form.Label>
                    <Row>
                      <Col>
                        <Form.Control type="file" onChange={handleImage} />
                      </Col>
                      <Col>
                        { image ? <img width='100' src={image} alt="" /> : ''}
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Status</Form.Label>
                    <Form.Control as="select" ref={status} onChange={handleChange} value={fields.status}>
                      <option value="true">Active</option>
                      <option value="false">In-Active</option>                
                    </Form.Control>
                  </Form.Group>
                </Form.Row>
                <Button variant="primary" className="float-right" type="submit">
                  Save
                </Button>
              </Form>                
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}