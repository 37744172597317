import React, {useEffect, useState} from "react";
import axios from "axios";
import $ from "jquery";
import {Link} from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router';
import { toAbsoluteUrl } from "../../../_helpers";
import SVG from "react-inlinesvg";
import alertify from "alertifyjs";
import {API_URL, faq} from '../constants.js';
import { useSelector } from "react-redux";

export function FaqList(props){
	const { userId, role } = useSelector((state) => state.Auth.user);
	const [faqs, setFaqs] = useState([]);
	const [faqInfo, setFaq] = useState(null);
	const [currentIndex, setCurrentIndex] = useState(-1);
	const [show, setShow] = useState(false);
	const history = useHistory()

	const setCurrentFaq = (faq, index) => {
	    setFaq(faq);
	    setCurrentIndex(index);
	    setShow(true);

	};

  	const handleClose = () => setShow(false);

  	const deleteFaq = () => {
		axios.delete(`${API_URL}${faq}/${userId}/${faqInfo.uuid}`).then((response) => {
			const { success, errors = [], data, message } = response?.data;
			if(success){
				alertify.success("Record removed successfully");
	  			history.go(0);
	  			setShow(false);	  			
	  		}
  		});
  	}

	useEffect( () => {
		axios.get(`${API_URL}${faq}/all/0/2000`).then( (response) => {
			const { success, errors = [], data, message } = response?.data;
			if(success){	
                setFaqs(data);
                $("#faq_table").DataTable();
            }
		});
	}, []);

	return (
		<>
		<table id="faq_table" className="sharearide_datatable">
		    <thead>
		      <tr>
		        <th>ID</th>
		        <th>Title</th>
		        <th>Description</th>
		        <th>Order</th>
		        <th>Actions</th>
		      </tr>
		    </thead>
		    <tbody>
		      {faqs.length > 0 ? (
		        faqs.map((faq, index) => (
		          <tr key={faq.id}>
		            <td>{faq.id}</td>
		            <td>{faq.title}</td>
		            <td>{faq.description}</td>
		            <td>{faq.order}</td>
		            <td>
		            	<Link to={`/faq/edit/${faq.uuid}`} className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
		            		<span className="svg-icon svg-icon-md svg-icon-primary">
					          <SVG
					            src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
					          />
					        </span>
        				</Link>
		            	<a 
		            	onClick={() => setCurrentFaq(faq, index)}
                		key={index}
                		className="btn btn-icon btn-light btn-hover-danger btn-sm">
		            		<span className="svg-icon svg-icon-md svg-icon-danger">
					          <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
					        </span>
		            	</a>

		            </td>
		            
		          </tr>
		        ))
		      ) : (
		        <tr>
		          <td colSpan={3}>No Faq</td>
		        </tr>
		      )}
		    </tbody>
		  </table>
		  <Modal show={show} onHide={handleClose}>
	        <Modal.Header closeButton>
	          <Modal.Title>Delete Faq</Modal.Title>
	        </Modal.Header>
	        <Modal.Body>Are you sure you want to Delete?</Modal.Body>
	        <Modal.Footer>
	          <Button variant="secondary" onClick={handleClose}>
	            Cancel
	          </Button>
	          <Button variant="primary" onClick={deleteFaq}>
	            Yes
	          </Button>
	        </Modal.Footer>
	      </Modal>
	      </>
	)
}