import React, { } from "react";
import CityList from "./CityList";
import {Link} from "react-router-dom";
import { Card, CardBody, CardHeader, CardHeaderToolbar, } from "../../../_metronic/_partials/controls";

export function Cities() {
  return (
  	<Card>
      <CardHeader title="City List">
        <CardHeaderToolbar>           
          <Link to="/city/create" className="btn btn-primary">Add New</Link>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>        
        <div className="mt-5"><CityList /></div>
      </CardBody>
    </Card>
  );
}