import React, { useEffect, useState, useRef } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import alertify from "alertifyjs";
import $ from "jquery";
import Select from 'react-select';
import { Card, CardBody, CardHeader,} from "../../../_metronic/_partials/controls";
import { days } from "../constants";
import { useSelector } from "react-redux";
import { LoaderScreen } from '../../modules/Loader';
import { getBusRoute, updateBusRoute } from "../../../app/sagas/busManagement";

export function EditBusRoute(props) {

  const [loading, setLoading] = useState(true);
  const { userId } = useSelector((state) => state.Auth.user);
	const [busRoute, setBusRoute] = useState({
    bus: {},
    route: {}
  });
  const [subRoutes, setSubRoutes] = useState([]);
  const [busSubRoutes, setBusSubRoutes] = useState([]);
  const [allowedDays, setAllowedDays] = useState([]);
	const departure = useRef();
  const selectedDays = useRef();
	const arrival = useRef();
  const fare = useRef();
	const id = props.match.params.id;
  const [inputValue, setInputValue] = useState('');
  const [menuIsOpen, setMenuIsOpen] = useState('');

  const onFareChange = (ref, data) =>{
    var index = busSubRoutes.findIndex(obj => obj.uuid === ref);
    busSubRoutes[index].fare = data;
    setBusSubRoutes([...busSubRoutes]);
  }
  const handleDaysChange = (e) => {
    allowedDays.splice(0, allowedDays.length);
    setAllowedDays([...allowedDays]);
    var options = e;
    for (var i = 0; i < options.length; i++) {
      allowedDays.push(options[i]);
    }
    setAllowedDays([...allowedDays]);
  }

  const focus = () => {
    setMenuIsOpen(true);
  };

  const onInputChange = (value, { action }) => {
    switch (action) {
      case 'input-change':
        setInputValue(value);
        return;
      case 'menu-close':
        let menuIsOpen = undefined;
        if (inputValue) {
          menuIsOpen = true;
        }
        setMenuIsOpen(menuIsOpen);
        return;
      default:
        return;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiResponse = await getBusRoute( id );
        const { success, data = {}, errors = [], message } = apiResponse?.data;
        if(success){
          setBusRoute(data);
          var selectedDays = data.allowedDays.substring(1);
          selectedDays = selectedDays.substring(0, selectedDays.length - 1);
          const allowed_days = selectedDays.split(',');        
          for(var i = 0; i < allowed_days.length; i++){
            allowedDays.push({
              label: allowed_days[i],
              value: allowed_days[i]
            });          
          }
          setAllowedDays([...allowedDays]);
          setSubRoutes(data.Route.SubRoutes)
          setBusSubRoutes(data.SubRoutes);
        } else{
          alertify.error(errors[0]?.errorMsg || message);
        }
      } catch (error) {
        alertify.error(error.message);
      } finally {
        $("#sub_route_table").DataTable();
        setLoading(false);
        setMenuIsOpen(false);
      }
		};
		fetchData();
	}, [id]);
	
  const editBusRouteHandler = async(e) => {
  	e.preventDefault();
    setLoading(true);
    try {
      const mappedDays = allowedDays.map(res => res.value);
      const apiResponse = await updateBusRoute( {
        "busRoute":{
          "id": busRoute.id,
          "uuid": busRoute.uuid,
          "departure": departure.current.value,
          "arrival": arrival.current.value,
          "fare": busRoute.fare,
          "status": busRoute.status, 
          "allowedDays": "'" + mappedDays + "'",  
          "createdBy": busRoute.createdBy,
          "updatedBy": userId,
          "deleted": busRoute.deleted,
          "busId": busRoute.busId,
          "routeId": busRoute.routeId,
        },
        "busSubRoute": busSubRoutes
      }, userId);
      const { success, errors = [] } = apiResponse?.data;
      if(success){
        alertify.success("Record updated successfully");
        props.history.push("/bus-route/list");
      } else{
        alertify.error(errors[0].errorMsg);
      }
    } catch (error) {
      alertify.error(error.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    loading ? <LoaderScreen /> :
      <Row>
        <Col md={12}>
        <Card>
          <CardHeader title="Edit Bus Route"/>
          <CardBody>        
            <div className="mt-5">
              <Form onSubmit={editBusRouteHandler}>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label><strong>Bus</strong></Form.Label> <br/>
                    <Form.Label>{busRoute?.Bus?.regNo || ""}</Form.Label>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label><strong>Route</strong></Form.Label>
                    <br/>
                    <Form.Label>{busRoute?.Route?.name || ""}</Form.Label>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Departure</Form.Label>
                    <Form.Control ref={departure} defaultValue={busRoute.departure} type="time"/>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Arrival</Form.Label>
                    <Form.Control ref={arrival} defaultValue={busRoute.arrival} type="time"/>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Operational Days</Form.Label>
                    <Select ref={selectedDays} onFocus={focus} isMulti isClearable isSearchable onInputChange={onInputChange} name="days"  options={days}
                      menuIsOpen={menuIsOpen} onChange={handleDaysChange} value={allowedDays.length > 0 ? allowedDays : []}/>
                  </Form.Group>
                  <Form.Group as={Col}/>
                </Form.Row>
                <Card>
                  <CardHeader title="Sub Routes"/>
                  <CardBody>              
                    <table id="sub_route_table" className="sharearide_datatable">
                      <thead>
                        <tr>
                          <th>Sub Route</th>
                          <th>Fare</th>
                          <th>Distance</th>
                          <th>Approximate Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {busSubRoutes.length > 0 ? (
                          busSubRoutes.map( (subRoute) => {
                            return (
                              <tr key={subRoute.uuid}>
                                <td>
                                  <Form.Label>{subRoute?.name }</Form.Label>
                                </td>
                                <td>
                                  <Form.Control className="fare" placeholder="Fare" type="number" min={0} step="0.01" defaultValue={subRoute.fare} onChange={(e) => onFareChange(subRoute.uuid, e.target.value)}/>
                                </td>
                                <td>
                                  <Form.Control className="distance" style={{background: '#D3D3D3'}} readOnly={true} placeholder="Distance" defaultValue={subRoute.distance}/>
                                </td>
                                <td>
                                  <Form.Control className="distance" style={{background: '#D3D3D3'}} readOnly={true} placeholder="Approximate Time" defaultValue={subRoute.approximateTime}/>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={4} align="center">No Sub routes</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </CardBody>
                </Card>
              <Button variant="primary" className="float-right" type="submit">
                Save
              </Button>
			      </Form>
          </div>
        </CardBody>
      </Card>
    </Col>
  </Row>
  );
}