export const AuthRouting = {
	superAdminDashboard: '/dashboard/super',
	adminDashboard: '/dashboard/admin',
	agentDashboard: '/dashboard/agent',
	ecommerce: '/e-commerce',
	bustypelist: '/bus-type/list',
	bustypecreate: '/bus-type/create',
	bustypeedit: '/bus-type/edit/:id',
	busfacilitylist: '/bus-facility/list',
	busfacilitycreate: '/bus-facility/create',
	busfacilityedit: '/bus-facility/edit/:id',
	faqlist: '/faq/list',
	faqcreate: '/faq/create',
	faqedit: '/faq/edit/:id',
	terminallist: '/terminal/list',
	terminalcreate: '/terminal/create',
	terminaledit: '/terminal/edit/:id',
	citylist: '/city/list',
	citycreate: '/city/create',
	cityedit: '/city/edit/:id',
	partnerlist: '/partner/list',
	partnercreate: '/partner/create',
	partneredit: '/partner/edit/:id',
	routelist: '/route/list',
	routecreate: '/route/create',
	routeedit: '/route/edit/:id',
	withdrawal: '/withdrawal/:status',
	userlist: '/user/all',
	userdetail: '/user/detail/:id/:page',
	agentlist: '/agent/list',
	agentcreate: '/agent/create',
	agentedit: '/agent/edit/:id',
	buslist: '/bus/list',
	buscreate: '/bus/create',
	busedit: '/bus/edit/:id',
	busview: '/bus/view/:id',
	contact: '/contact/list',
	bookinglist: '/booking/list',
	bookingdetails: '/booking/detail/:id',
	bookingcreate: '/booking/create',
	busroutelist: '/bus-route/list',
	busroutecreate: '/bus-route/create',
	busrouteedit: '/bus-route/edit/:id',

}

export const NonAuthRoutes = {
	login: '/',
	support: '/support',
	unauthorized: '/unauthorized'
}