import { PURGE } from "redux-persist";
import { events } from "../actions";

const resetPassword = (
  state = {
    errorCode: null,
    params: null,
    status: null,
    lockStartTime: null,
    errorMessage: null,
  },
  response
) => {
  switch (response.type) {
    case events.changePassword.RECEIVED:
      return {
        ...state,
        errorCode: null,
        status: true,
        params: null,
        lockStartTime: null
      };
    case events.changePassword.FAILED:
      return {
        ...state,
        status: false,
        errorCode: response.errorCode,
        params: response.params,
        lockStartTime: response.lockStartTime,
        errorMessage: response.message
      };
    case events.changePassword.RESET: {
      return {
        ...state,
        errorCode: null,
        params: null,
        status: null,
        lockStartTime: null
      };
    }
    case PURGE:
      return {
        errorCode: null,
        params: null,
        status: null,
        lockStartTime: null
      };
    default:
      return state;
  }
};

export { resetPassword };
