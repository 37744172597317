/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {NavLink}  from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { checkIsActive} from "../../../../../_helpers";
import { useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.text.primary,
  },
  icon: {
    margin: theme.spacing(1),
    fontSize: 32,
  },
}));

export function AsideMenuDashboardList({ layoutProps }) {
  const { role } = useSelector((state) => state.Auth.user);
  var dashboard ="";
    if(role === "super"){
      dashboard = "/dashboard/super";
    } else if(role === "admin"){
      dashboard = "/dashboard/admin";
    } else if(role === "agent"){
      dashboard = "/dashboard/agent";
    }
  const classes = useStyles();
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
        ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
        : "";
  };

  return (
      <>
        {/* begin::Menu Nav */}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        
          {/* Dashboard */}
          {/* begin::section */}
          <li className="menu-section ">
            <h4 className="menu-text">Dashboard </h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>
          {/* end:: section */}

          {/* Agents Type Pages */}
          {/*begin::1 Level*/}
          <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/bus-type", true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
          >
            <NavLink className="menu-link" to={dashboard}>
              <span className="svg-icon menu-icon">
                <DashboardIcon className={classes.icon} />
              </span>
              <span className="menu-text">Dashboard</span>
            </NavLink>
            
          </li>
          {/*end::1 Level*/}
          
        </ul>

        {/* end::Menu Nav */}
      </>
  );
}
