import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import alertify from "alertifyjs";
import {API_URL, busManagement} from '../constants.js';
import { handleResponse } from '../../../_helpers';

import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";


import { useSelector } from "react-redux";
import { LoaderScreen } from '../../modules/Loader';
import { getBusFacilityDetails, updateBusFacilityDetails } from "../../../app/sagas/busManagement";

export function EditBusFacility(props) {

	const { userId } = useSelector((state) => state.Auth.user);
	const [loading, setLoading] = useState(true);
	const id = props.match.params.id;

	const initialState = {
		type: '',
		status: '',
	};

	const [type, setType] = useState({});
	const [fields, setFields] = useState(initialState);
  	const name = useRef();
  	const status = useRef();

	useEffect(() => {
		const fetchData = async () => {
			try {
				const apiResponse = await getBusFacilityDetails( id );
				const { success, data = {}, errors = [], message } = apiResponse?.data;
				if(success){
				setFields({
					status: data.status
				});
				setType(data);
				} else{
				alertify.error(errors[0]?.errorMsg || message);
				}
			} catch (error) {
				alertify.error(error.message);
			} finally {
				setLoading(false);
			}	
		};
		fetchData();
	}, [id]);
	
	const handleChange = (e) => {
		setFields({status: e.target.value});
	}

	const editBusFacilityHandler = async(e) => {
		e.preventDefault();
		setLoading(true);
		try {
			const apiResponse = await updateBusFacilityDetails({
				"busFacility":{
					"id": type.id,
					"uuid": type.uuid,
					"name": name.current.value,
					"status": status.current.value,
					"createdBy": type.createdBy,
					"updatedBy": type.updatedBy,
					"deleted": type.deleted
				}
			}, userId);
			const { success, errors = [] } = apiResponse?.data;
			if(success){
				alertify.success("Record updated successfully");
				props.history.push("/bus-facility/list");
			} else{
				alertify.error(errors[0].errorMsg);
			}
		} catch (error) {
			alertify.error(error.message);
		} finally {
			setLoading(false);
		}
	}
  return (
	loading ? <LoaderScreen />:
	<Row>
		<Col md={12}>
		<Card>
		<CardHeader title="Edit Bus Facility"/>
		<CardBody>
			
			<div className="mt-5">
				<Form onSubmit={editBusFacilityHandler}>
				<Form.Row>
				<Form.Group as={Col}>
					<Form.Label>Name</Form.Label>
					<Form.Control ref={name} defaultValue={type.name} placeholder="Enter name" />
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Status</Form.Label>
					<Form.Control as="select" ref={status} onChange={handleChange} value={fields.status}>
					<option value="true">Active</option>
					<option value="false">In-Active</option>			      
					</Form.Control>
				</Form.Group>
				</Form.Row>
				<Button variant="primary" className="float-right" type="submit">
					Save
				</Button>
				</Form>             
			</div>
		</CardBody>
		</Card>
		</Col>
	</Row>
  );
}