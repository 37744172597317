import React, {useEffect, useState} from "react";
import axios from "axios";
import $ from "jquery";
import {Link} from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router';
import { toAbsoluteUrl } from "../../../_helpers";
import SVG from "react-inlinesvg";
import alertify from "alertifyjs";
import {API_URL, busManagement} from '../constants.js';


import { getBusFacilityLists, removeBusFacilityDetails } from "../../../app/sagas/busManagement";
import { useSelector } from "react-redux";
import { LoaderScreen } from '../../modules/Loader';

export function BusFacilityList(props){

	const [ loading, setLoading ] = useState(true);
  	const { user } = useSelector((state) => state.Auth);
	const currentUser = user ? user : {};
	const [deleting, setDeleting] = useState(false);

	const [busFacilities, setBusFacilities] = useState([]);
	const [busFacility, setBusFacility] = useState(null);
	const [show, setShow] = useState(false);
	const history = useHistory()
	const [offset, setOffset] = useState(0);
	const [pageLimit, setPageLimit] = useState(2000);
	const [totalPages, setTotalPages] = useState(null);

	const setCurrentBusFacility = (id) => {
	    setBusFacility(id);
	    setShow(true);
	};

	useEffect(() => {
		const fetchData = async () => {
			const apiResponse = await getBusFacilityLists(offset, pageLimit);
			const { data = [], success } = apiResponse?.data;
			if (success) {
				setBusFacilities(data);
			}
		};
		fetchData();
	}, []);

	useEffect(() => {
		if(busFacilities.length > 0){
			$("#bus_facility_table").DataTable({responsive: true, stateSave: true});			
			setTotalPages(busFacilities.length);
			setOffset(busFacilities.length + 1);
			setPageLimit(busFacilities.length + 100);
		}
		setLoading(false);
	}, [busFacilities]);

  	const deleteBusFacility = async() => {
		try {
			setDeleting(true);
			const apiResponse = await removeBusFacilityDetails(busFacility, currentUser.userId);
			const { success, errors = [] } = apiResponse?.data;
			if(success){
			  alertify.success("Record removed successfully");
			  history.go(0);
			} else{
			  alertify.error(errors[0].errorMsg);
			}
		} catch (error) {
			alertify.error(error.message);
		} finally {
			setShow(false);
			setDeleting(false);
		}
  	}

	return (
		loading ? <LoaderScreen /> :
		<>
			<table id="bus_facility_table" className="sharearide_datatable">
				<thead>
				<tr>
					<th>ID</th>
					<th>Name</th>
					<th>Actions</th>
				</tr>
				</thead>
				<tbody>
					{busFacilities.length > 0 ? (
						busFacilities.map((busFacility, index) => (
						<tr key={busFacility.id}>
							<td>{busFacility.id}</td>
							<td>{busFacility.name}</td>
							<td>
								<Link to={`/bus-facility/edit/${busFacility.uuid}`} className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
									<span className="svg-icon svg-icon-md svg-icon-primary">
									<SVG
										src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
									/>
									</span>
								</Link>
								<a onClick={() => setCurrentBusFacility(busFacility.uuid)} key={index} className="btn btn-icon btn-light btn-hover-danger btn-sm">
									<span className="svg-icon svg-icon-md svg-icon-danger">
									<SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
									</span>
								</a>
							</td>		            
						</tr>
						))
					) : (
						<tr>
						<td colSpan={3}>No Bus Facility</td>
						</tr>
				)}
				</tbody>
			</table>
			<Modal show={show} onHide={() => setShow(false)}>
				<Modal.Header closeButton>
				<Modal.Title>Delete Bus Facility</Modal.Title>
				</Modal.Header>
				<Modal.Body>Are you sure you want to Delete?</Modal.Body>
				<Modal.Footer>
				<Button variant="secondary" onClick={() => setShow(false)} disabled={ deleting }>
					Cancel
				</Button>
				<Button variant="primary" onClick={deleteBusFacility} disabled={ deleting }>
				<span>Yes</span>{deleting && <span className="ml-2 spinner spinner-white"></span>}&nbsp;&nbsp;
				</Button>
				</Modal.Footer>
			</Modal>
	    </>
	)
}