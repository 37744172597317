import React, {Suspense, lazy} from "react";
import {Redirect, Switch, Route} from "react-router-dom";
import {LayoutSplashScreen} from "../_metronic/layout";
import { LOCAL_STORAGE_CUSTOMER_DATA_KEY } from "./pages/constants";

import {DashboardPage} from "./pages/dashboards/SuperDashboardPage";
import {AdminDashboardPage} from "./pages/dashboards/AdminDashboardPage";
import {AgentDashboardPage} from "./pages/dashboards/AgentDashboardPage";

import {BusTypes} from "./pages/BusTypes/BusTypes";
import {CreateBusType} from "./pages/BusTypes/CreateBusType";
import {EditBusType} from "./pages/BusTypes/EditBusType";

import {BusFacilities} from "./pages/BusFacilities/BusFacilities";
import {CreateBusFacility} from "./pages/BusFacilities/CreateBusFacility";
import {EditBusFacility} from "./pages/BusFacilities/EditBusFacility";

import {Faqs} from "./pages/Faqs/Faqs";
import {CreateFaq} from "./pages/Faqs/CreateFaq";
import {EditFaq} from "./pages/Faqs/EditFaq";

import {Terminals} from "./pages/Terminals/Terminals";
import {CreateTerminal} from "./pages/Terminals/CreateTerminal";
import {EditTerminal} from "./pages/Terminals/EditTerminal";

import {Cities} from "./pages/Cities/Cities";
import {CreateCity} from "./pages/Cities/CreateCity";
import {EditCity} from "./pages/Cities/EditCity";

import {Partners} from "./pages/Partners/Partners";
import {CreatePartner} from "./pages/Partners/CreatePartner";
import {EditPartner} from "./pages/Partners/EditPartner";

import {Routes} from "./pages/Routes/Routes";
import {CreateRoute} from "./pages/Routes/CreateRoute";
import {EditRoute} from "./pages/Routes/EditRoute";

import {Withdrawals} from "./pages/Withdrawals/Withdrawals";

import {Users} from "./pages/Users/Users";
import {UserDetail} from "./pages/Users/UserDetail";

import {Agents} from "./pages/Agents/Agents";
import {CreateAgent} from "./pages/Agents/CreateAgent";
import {EditAgent} from "./pages/Agents/EditAgent";

import {Buses} from "./pages/Buses/Buses";
import {CreateBus} from "./pages/Buses/CreateBus";
import {EditBus} from "./pages/Buses/EditBus";
import {ViewBus} from "./pages/Buses/ViewBus";

import {Contacts} from "./pages/Contacts/Contacts";

import {Bookings} from "./pages/Bookings/Bookings";
import {BookingDetail} from "./pages/Bookings/BookingDetail";
import {BookingCreate} from "./pages/Bookings/BookingCreate";

import {BusRoutes} from "./pages/BusRoutes/BusRoutes";
import {CreateBusRoute} from "./pages/BusRoutes/CreateBusRoute";
import {EditBusRoute} from "./pages/BusRoutes/EditBusRoute";
import { Unauthorized } from "./pages/Unauthorized/Unauthorized";
import { AuthRouting } from '../_helpers/routingManagement';
import { AuthRoutes } from '../_components/AdminRoutes';
import { Role } from '../_helpers/role';

import { useSelector } from "react-redux";

export default function BasePage() {
  const userData = window.localStorage.getItem(LOCAL_STORAGE_CUSTOMER_DATA_KEY);
  const isAuthenticated = useSelector((state) => state.Auth.isAuthenticated) || false;

  var dashboard ="";
  if(!isAuthenticated){
    dashboard = "/auth/login";
  } else{
    if(userData){
      const user = JSON.parse(userData);
      const role = user?.role;
      if(role === "super"){
        dashboard = "/dashboard/super";
      } else if(role === "admin"){
        dashboard = "/dashboard/admin";
      } else if(role === "agent"){
        dashboard = "/dashboard/agent";
      }
    }    
  }

    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                {
                  <Redirect exact from="/auth/login/" to={dashboard}/>
                }
                {
                  <Redirect exact from="/" to={dashboard}/>
                }

                <AuthRoutes exact path={AuthRouting.superAdminDashboard} Component={DashboardPage} requiredRoles={[ String(Role.Super)]}/>
                <AuthRoutes exact path={AuthRouting.adminDashboard} Component={AdminDashboardPage} requiredRoles={[ String(Role.Admin)]}/>
                <AuthRoutes exact path={AuthRouting.agentDashboard} Component={AgentDashboardPage} requiredRoles={[ String(Role.Agent)]}/>

                <AuthRoutes path={AuthRouting.bustypelist} Component={BusTypes} requiredRoles={[ String(Role.Super)	]}/>
                <AuthRoutes path={AuthRouting.bustypecreate} Component={CreateBusType} requiredRoles={[ String(Role.Super)	]}/>
                <AuthRoutes path={AuthRouting.bustypeedit} Component={EditBusType} requiredRoles={[ String(Role.Super)	]}/>

                <AuthRoutes path={AuthRouting.busfacilitylist} Component={BusFacilities} requiredRoles={[ String(Role.Super)	]}/>
                <AuthRoutes path={AuthRouting.busfacilitycreate} Component={CreateBusFacility} requiredRoles={[ String(Role.Super)	]}/>
                <AuthRoutes path={AuthRouting.busfacilityedit} Component={EditBusFacility} requiredRoles={[ String(Role.Super)	]}/>

                <AuthRoutes path={AuthRouting.faqlist} Component={Faqs} requiredRoles={[ String(Role.Agent), String(Role.Admin), String(Role.Super)	]}/>
                <AuthRoutes path={AuthRouting.faqcreate} Component={CreateFaq} requiredRoles={[ String(Role.Super)	]}/>
                <AuthRoutes path={AuthRouting.faqedit} Component={EditFaq} requiredRoles={[ String(Role.Super)	]}/>

                <AuthRoutes path={AuthRouting.terminallist} Component={Terminals} requiredRoles={[ String(Role.Super)	]}/>
                <AuthRoutes path={AuthRouting.terminalcreate} Component={CreateTerminal} requiredRoles={[ String(Role.Super)	]}/>
                <AuthRoutes path={AuthRouting.terminaledit} Component={EditTerminal} requiredRoles={[ String(Role.Super)	]}/>

                <AuthRoutes path={AuthRouting.citylist} Component={Cities} requiredRoles={[ String(Role.Super)	]}/>
                <AuthRoutes path={AuthRouting.citycreate} Component={CreateCity} requiredRoles={[ String(Role.Super)	]}/>
                <AuthRoutes path={AuthRouting.cityedit} Component={EditCity} requiredRoles={[ String(Role.Super)	]}/>

                <AuthRoutes path={AuthRouting.partnerlist} Component={Partners} requiredRoles={[ String(Role.Super)	]}/>
                <AuthRoutes path={AuthRouting.partnercreate} Component={CreatePartner} requiredRoles={[ String(Role.Super)	]}/>
                <AuthRoutes path={AuthRouting.partneredit} Component={EditPartner} requiredRoles={[ String(Role.Super)	]}/>

                <AuthRoutes path={AuthRouting.routelist} Component={Routes} requiredRoles={[ String(Role.Super)	]}/>
                <AuthRoutes path={AuthRouting.routecreate} Component={CreateRoute} requiredRoles={[ String(Role.Super)	]}/>
                <AuthRoutes path={AuthRouting.routeedit} Component={EditRoute} requiredRoles={[ String(Role.Super)	]}/>

                <AuthRoutes path={AuthRouting.withdrawal} Component={Withdrawals} requiredRoles={[ String(Role.Admin), String(Role.Super)	]}/>
                
                <AuthRoutes path={AuthRouting.userlist} Component={Users} requiredRoles={[ String(Role.Super)	]}/>
                <AuthRoutes path={AuthRouting.userdetail} Component={UserDetail} requiredRoles={[ String(Role.Super)	]}/>

                <AuthRoutes path={AuthRouting.agentlist} Component={Agents} requiredRoles={[ String(Role.Admin), String(Role.Super)	]}/>
                <AuthRoutes path={AuthRouting.agentcreate} Component={CreateAgent} requiredRoles={[ String(Role.Admin), String(Role.Super)	]}/>
                <AuthRoutes path={AuthRouting.agentedit} Component={EditAgent} requiredRoles={[ String(Role.Admin), String(Role.Super)	]}/>

                <AuthRoutes path={AuthRouting.buslist} Component={Buses} requiredRoles={[ String(Role.Admin), String(Role.Super)	]}/>
                <AuthRoutes path={AuthRouting.buscreate} Component={CreateBus} requiredRoles={[ String(Role.Super)	]}/>
                <AuthRoutes path={AuthRouting.busedit} Component={EditBus} requiredRoles={[ String(Role.Super)	]}/>
                <AuthRoutes path={AuthRouting.busview} Component={ViewBus} requiredRoles={[ String(Role.Admin), String(Role.Super)	]}/>

                <AuthRoutes path={AuthRouting.bookinglist} Component={Bookings} requiredRoles={[ String(Role.Admin), String(Role.Super), String(Role.Agent)	]}/>
                <AuthRoutes path={AuthRouting.bookingdetails} Component={BookingDetail} requiredRoles={[ String(Role.Admin), String(Role.Super), String(Role.Agent)	]}/>
                <AuthRoutes path={AuthRouting.bookingcreate} Component={BookingCreate} requiredRoles={[ String(Role.Admin), String(Role.Super), String(Role.Agent)	]}/>

                <AuthRoutes path={AuthRouting.busroutelist} Component={BusRoutes} requiredRoles={[ String(Role.Super)	]}/>
                <AuthRoutes path={AuthRouting.busroutecreate} Component={CreateBusRoute} requiredRoles={[ String(Role.Super)	]}/>
                <AuthRoutes path={AuthRouting.busrouteedit} Component={EditBusRoute} requiredRoles={[ String(Role.Super)	]}/>
                
                <AuthRoutes path={AuthRouting.contact} Component={Contacts} requiredRoles={[ String(Role.Admin), String(Role.Super)	]}/>
                
                <Route exact={true} path="/unauthorized" component={Unauthorized} />

                <Redirect to="error/error-v1"/>                
            </Switch>
        </Suspense>
    );
}
