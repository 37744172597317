import { PURGE } from "redux-persist";
import { events } from "../actions";

const getInitialState = () => {
  const INITIAL_DATA = {
    errorMessage: "",
    loaders: {
      initiateAPI: false, //for fetching single item record
      submitAPI: false, //for post record event
      discardAPI: false, //for delete record event
      allCitiesAPI: false, // for fetching cities records
    },
    city: {
      loading: false,
      data: null,
      error: null,
    },
    discardResponse: null,
    allCities: [],
  };
  return INITIAL_DATA;
};
export default (state = getInitialState(), response) => {
  switch (response.type) {
    case PURGE:
      return getInitialState();
       
    case events.routesManagement.city.UPDATE_CITY:
      return {
        ...state,
        city: {
          ...state.city,
          loading: true,
          error: null,
        },
      };
    case events.routesManagement.city.UPDATE_CITY_RECEIVED:
      return {
        ...state,
        city: {
          loading: false,
          data: response.payload,
          error: null,
        },
      };
    case events.routesManagement.city.UPDATE_CITY_FAILED:
      return {
        ...state,
        city: {
          ...state.city,
          loading: false,
          error: response.errorCode,
        },
      };
    

    case events.routesManagement.city.FETCH_CITY:
      return {
        ...state,
        detailedCity: {
          ...state.city,
          loading: true,
          error: null,
        },
      };
    case events.routesManagement.city.FETCH_CITY_SUCCESS:
      return {
        ...state,
        detailedCity: {
          loading: false,
          data: response.payload,
          error: null,
        },
      };
    case events.routesManagement.city.FETCH_CITY_FAILED:
      return {
        ...state,
        detailedCity: {
          ...state.city,
          loading: false,
          error: response.errorCode,
        },
      };
    

    case events.routesManagement.city.FETCH_CITY_LIST: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          allCitiesAPI: true,
        },
        allCities: [],
      };
    }
    case events.routesManagement.city.FETCH_CITY_LIST_SUCCESS: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          allCitiesAPI: false,
        },
        allCities: response.payload,
      };
    }
    case events.routesManagement.city.FETCH_CITY_LIST_FAILED: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          allCitiesAPI: false,
        },
      };
    }



    case events.routesManagement.city.REMOVE_CITY:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          discardAPI: true,
        },
      };
    case events.routesManagement.city.REMOVE_CITY_SUCCESS:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          discardAPI: false,
        },
        discardResponse: response.payload
      };
    case events.routesManagement.city.REMOVE_CITY_FAILED:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          discardAPI: false,
        },
        errorMessage: response.message
      };
    

    
    default:
      return state;
  }
};
