import React, {useEffect, useState } from "react";
import $ from "jquery";
import {Link} from "react-router-dom";
import { useHistory } from 'react-router';
import { toAbsoluteUrl } from "../../../_helpers";
import SVG from "react-inlinesvg";
import alertify from "alertifyjs";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Nav from 'react-bootstrap/Nav';
import TabPane from 'react-bootstrap/TabPane';
import TabContent from 'react-bootstrap/TabContent';
import TabContainer from 'react-bootstrap/TabContainer';

import { Card, CardBody, CardHeader, CardHeaderToolbar,} from "../../../_metronic/_partials/controls";


import { useSelector } from "react-redux";
import { LoaderScreen } from '../../modules/Loader';
import { getUser, changeAccountStatus } from "../../../app/sagas/userManagement";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export function UserDetail(props){
	const [loading, setLoading] = useState(true);
	const { userId } = useSelector((state) => state.Auth.user);
	const [userInfo, setUser] = useState({});
	const history = useHistory();
	const id = props.match.params.id;
	const page = props.match.params.page;
	const [ openModal, setOpenModal ] = useState(false);
	const [deleting, setDeleting] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const apiResponse = await getUser(id);
				const { data = [], success } = apiResponse?.data;
				if (success) {
					setUser(data);
				}
			} catch (error) {
				alertify.error(error.message);
			}
		};
		fetchData();
	}, [id]);
	
	useEffect(() => {
		if(userInfo.length > 0){
			$("#booking_table").DataTable({responsive: true, stateSave: true});
			$("#withdrawal_table").DataTable({responsive: true, stateSave: true});
			$("#transaction_table").DataTable({responsive: true, stateSave: true});
		}
		setLoading(false)
	}, [userInfo]);

	const actDeaAccCtaHandler = async() => {
		try {
			setDeleting(true)
			const status = userInfo.user.status === "ACTIVE" ? "INACTIVE" : "ACTIVE";
			const apiResponse = await changeAccountStatus(userId, status, id);
			const { success, errors = [] } = apiResponse?.data;
			if(success){
			  alertify.success(`Account ${status === "ACTIVE" ? "Deactivated" : "Activated"} successfully`);
			  history.go(0);
			} else{
			  alertify.error(errors[0].errorMsg);
			}
		} catch (error) {
			alertify.error(error.message);
		} finally {
			setOpenModal(false);
			setDeleting(false);
		}
  	}

	return (
		loading ? <LoaderScreen /> :
		<>
			<Row>
				<Col md={12}>
					<Card>
						<CardHeader title="User Detail">
							<CardHeaderToolbar>
								<Button variant={ userInfo?.user?.status === "ACTIVE" ? "secondary" : "primary" } onClick={()=>setOpenModal(true)}> 
									{ userInfo?.user?.status === "ACTIVE" ? "Deactivate" : "Activate" }
								</Button>
							</CardHeaderToolbar>
						</CardHeader>
						<CardBody>
							<TabContainer id="left-tabs-example" defaultActiveKey="user_detail">
								<Row>
									<Col sm={3}>
									<Nav variant="pills" className="flex-column">
										<Nav.Item>
										<Nav.Link eventKey="user_detail">Personal Information</Nav.Link>
										</Nav.Item>
										<Nav.Item>
										<Nav.Link eventKey="booking_history">Booking History</Nav.Link>
										</Nav.Item>
										<Nav.Item>
										<Nav.Link eventKey="transactions">Transaction History</Nav.Link>
										</Nav.Item>
									</Nav>
									</Col>
									<Col sm={9}>
										<TabContent>
											<TabPane eventKey="user_detail">
												<table id="user_table" className="table table-striped table-bordered ">
													<tbody>
														<tr>
															<th>ID</th>						        
															<td>{userInfo?.user?.id}</td>					            
														</tr>
														<tr>
															<th>First Name</th>
															<td>{userInfo?.user?.fname}</td>
														</tr>
														<tr>
															<th>Last Name</th>
															<td>{userInfo?.user?.lname}</td>					          	
														</tr>
														<tr>
															<th>Email</th>
															<td>{userInfo?.authDetails?.email}</td>					          	
														</tr>
														<tr>
															<th>Username</th>
															<td>{userInfo?.authDetails?.username}</td>					          	
														</tr>
														<tr>
															<th>Phone</th>
															<td>{userInfo?.user?.phone}</td>					          	
														</tr>
													</tbody>
												</table>
											</TabPane>
											<TabPane eventKey="booking_history">
											<table id="booking_table" className="sharearide_datatable">
												<thead>
												<tr>
													<th>ID</th>
													<th>First Name</th>
													<th>Last Name</th>
													<th>Bus</th>							
													<th>Sub Route</th>
													<th>Seats</th>
													<th>Fare</th>
													<th>Actions</th>
												</tr>
												</thead>
												<tbody>
												{userInfo?.bookingsDetails?.length > 0 ? (
													userInfo?.bookingsDetails.map((booking, index) => (
													<tr key={index}>
														<td>{booking?.id}</td>
														<td>{booking?.User?.fname}</td>
														<td>{booking?.User?.lname}</td>
														<td>{booking?.Bus?.regNo}</td>
														<td>{booking?.Bus?.SubRoute?.name}</td>
														<td>{booking?.seats}</td>
														<td>{booking?.fare}</td>
														<td>
															<Link to={`/booking/detail/${booking.uuid}`} className="btn btn-icon btn-light btn-hover-success  btn-sm mx-3">
																<span className="svg-icon svg-icon-md svg-icon-success ">
																<SVG
																	src={toAbsoluteUrl("/media/svg/icons/General/Visible.svg")}
																/>
																</span>
															</Link>			        				
														</td>					            
													</tr>
													))
												) : (
													<tr>
													<td colSpan="8" align="center">No Booking</td>
													</tr>
												)}
												</tbody>
											</table>
											</TabPane>
											<TabPane eventKey="transactions">
												<table id="transaction_table" className="sharearide_datatable">
													<thead>
														<tr>
															<th>ID</th>
															<th>Amount</th>
															<th>Account No</th>
															<th>Bank Name</th>
															<th>Branch Code</th>
															<th>IBAN</th>
															<th>Type</th>
															<th>Status</th>
														</tr>
													</thead>
													<tbody>
														{userInfo?.transactions?.length > 0 ? (
															userInfo?.transactions.map((transaction, index) => (
															<tr key={index}>
																<td>{transaction?.id}</td>
																<td>{transaction?.amount}</td>
																<td>{transaction?.accountNumber}</td>
																<td>{transaction?.bankName}</td>
																<td>{transaction?.branchCode}</td>
																<td>{transaction?.ibanNumber}</td>
																<td>{transaction?.type}</td>
																<td>{transaction?.status}</td>					     
															</tr>
															))
														) : (
															<tr>
																<td colSpan="8" align="center">No Transaction records</td>
															</tr>
														)}
													</tbody>
												</table>
											</TabPane>
										</TabContent>
									</Col>
								</Row>
							</TabContainer>
						</CardBody>
					</Card>
				</Col>
			</Row>
			<Modal show={openModal} onHide={() => setOpenModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{ userInfo?.user?.status === "ACTIVE" ? "Deactivate" : "Activate" } User Account</Modal.Title>
				</Modal.Header>
				<Modal.Body>Are you sure you want to { userInfo?.user?.status === "ACTIVE" ? "Deactivate" : "Activate" } this account?</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setOpenModal(false)} disabled={ deleting }>
						Cancel
					</Button>
					<Button variant="primary" onClick={actDeaAccCtaHandler} disabled={ deleting }>
						<span>Yes</span> {deleting && <span className="ml-2 spinner spinner-white"></span>}&nbsp;&nbsp;
					</Button>
				</Modal.Footer>
			</Modal>
	    </>
	)
}