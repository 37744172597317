import React, { } from "react";
import {BusFacilityList} from "./BusFacilityList";
import {Link} from "react-router-dom";

import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";

export function BusFacilities() {
  return (
  	<Card>
      <CardHeader title="Bus Facility List">
        <CardHeaderToolbar>           
          <Link to="/bus-facility/create" className="btn btn-primary">Add New</Link>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>        
        <div className="mt-5">
          <BusFacilityList />
        </div>
      </CardBody>
    </Card>
  );
}