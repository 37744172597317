import React, { useEffect, useState, useRef } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import alertify from "alertifyjs";
import { makeStyles } from '@material-ui/core/styles';
import Add from '@material-ui/icons/Add';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import { Card, CardBody, CardHeader, CardHeaderToolbar,} from "../../../_metronic/_partials/controls";
import { useSelector } from "react-redux";
import { LoaderScreen } from '../../modules/Loader';
import { getCityLists, getRouteDetails, updateRouteDetails } from "../../../app/sagas/routeManagement";

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
}));

export function EditRoute(props) {
  
  const classes = useStyles();
  const { userId } = useSelector((state) => state.Auth.user);
  const [loading, setLoading] = useState(true);
  const id = props.match.params.id;

	const [route, setRoute] = useState({});
	const [fields, setFields] = useState({ route: '', status: '', });
  const [startPoints, setStartPoints] = useState([]);
  const [endPoints, setEndPoints] = useState([]);
  const [subRoutes, setSubRoutes] = useState([]);
  const [removedSubRoutes, setRemovedSubRoutes] = useState([]);
  const name = useRef();
  const start_point = useRef();
  const end_point = useRef();
  const approximate_distance = useRef();
  const approximate_time_hours = useRef();
  const approximate_time_minutes = useRef();
  const departure_sequence_number = useRef();
  const arrival_sequence_number = useRef();
  const description = useRef();
  const status = useRef();

  const handleRowDel = (subRoute) =>{
    var index = subRoutes.findIndex(obj => obj.uuid === subRoute.uuid);
    subRoutes[index].deleted = true;
    removedSubRoutes.push(subRoutes[index]);    
    setRemovedSubRoutes([...removedSubRoutes]);
    subRoutes.splice(index, 1);
    setSubRoutes([...subRoutes]);
  };

  const handCreateSubRoute = () => {
    var id = (+ new Date() + Math.floor(Math.random() * 999999)).toString(36);
    var subRoute = {
      id: null,
      uuid: id,
      name: "",
      distance: 0,
      departureSequenceNumber: 0,
      arrivalSequenceNumber: 0,
      fare: "",  
      starting: "",
      ending: "",
      approximateTime: 0,
      isDefault: false,
      createdBy: userId,
      updatedBy: userId,
      deleted: false
    }
    subRoutes.push(subRoute);
    setSubRoutes([...subRoutes]);
  }

  const onNameChange = (uuid, data) =>{
    var index = subRoutes.findIndex(obj => obj.uuid === uuid);
    subRoutes[index].name = data;
    setSubRoutes([...subRoutes]);
  }  

  const onStartPointChange = (uuid, data) =>{
    var index = subRoutes.findIndex(obj => obj.uuid === uuid);
    subRoutes[index].starting = data;
    setSubRoutes([...subRoutes]);
  }
  const onEndpointChange = (uuid, data) =>{
    var index = subRoutes.findIndex(obj => obj.uuid === uuid);
    subRoutes[index].ending = data;
    setSubRoutes([...subRoutes]);
  }
  const onIsDefaultChange = (uuid, data) =>{
    var index = subRoutes.findIndex(obj => obj.uuid === uuid);
    subRoutes[index].isDefault = (String(data) == "true");
    if((String(data) == "true")){
      subRoutes[index].approximateTime = (parseInt((approximate_time_hours.current.value * 60 || 0)) + parseInt((approximate_time_minutes.current.value || 0)));
      subRoutes[index].distance = approximate_distance.current.value;
      subRoutes[index].name = name.current.value; 
      subRoutes[index].departureSequenceNumber = departure_sequence_number.current.value;
      subRoutes[index].arrivalSequenceNumber = arrival_sequence_number.current.value;
      subRoutes[index].starting = start_point.current.value;
      subRoutes[index].ending = end_point.current.value;
    }
    setSubRoutes([...subRoutes]);
  }
  const onApproximateTimeChange = (ref, data) =>{
    var index = subRoutes.findIndex(obj => obj.uuid === ref);
    subRoutes[index].approximateTime = data;
    setSubRoutes([...subRoutes]);
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiResponse = await getRouteDetails(id);
        const { success, data = {}, errors = [] } = apiResponse?.data;
        if(success){
          setFields({
            status: data.route.status,
            start_point: data.route.startPoint,
            end_point: data.route.endPoint
          });
          setRoute(data.route);
          setSubRoutes([...data.subRoutes]);

          const cityListApiResponse = await getCityLists(0, 200);
          if (cityListApiResponse?.data.success) {
            setStartPoints(cityListApiResponse?.data.data);
            setEndPoints(cityListApiResponse?.data.data);
          }

        } else{
          alertify.error(errors[0].errorMsg);
        }
      } catch (error) {
        alertify.error(error.message);
      } finally {
        setLoading(false);
      }	
		};
		fetchData();
	}, [id]);
	
	const handleChange = (e) => {
		setFields({status: e.target.value});
	}

  const handleStartPointChange = (e) => {
    setFields({start_point: e.target.value});
  }

  const handleEndPointChange = (e) => {
    setFields({end_point: e.target.value});
  }

  const onDistanceChange = (ref, data) =>{
    var index = subRoutes.findIndex(obj => obj.uuid === ref);
    subRoutes[index].distance = data;
    setSubRoutes([...subRoutes]);
  }

  const editRouteHandler = async(e) => {
  	e.preventDefault();
    setLoading(true);
    try {
      const apiResponse = await updateRouteDetails({
        "route":{
          "id": route.id,
          "uuid": route.uuid,
          "name": name.current.value,
          "departureSequenceNumber": route.departureSequenceNumber,
          "arrivalSequenceNumber": route.arrivalSequenceNumber,
          "fare": route.fare,
          "distance": approximate_distance.current.value,
          "approximateTime": (parseInt((approximate_time_hours.current.value * 60) || 0) + parseInt(approximate_time_minutes.current.value || 0)),
          "description": description.current.value,
          "status": status.current.value,
          "createdBy": route.createdBy,
          "updatedBy": route.updatedBy,
          "deleted": route.deleted,
          "startPoint": start_point.current.value,
          "endPoint": end_point.current.value
        },
        "subRoutes": subRoutes,
        "removedSubRoutes": removedSubRoutes
      }, userId);
      const { success, errors = [] } = apiResponse?.data;
      if(success){
        alertify.success("Record updated successfully");
        props.history.push("/route/list");
      } else{
        alertify.error(errors[0].errorMsg);
      }
    } catch (error) {
      alertify.error(error.message);
    } finally {
      setLoading(false);
    }
  }
  return (
    loading ? <LoaderScreen />:
    <Row>
      <Col md={12}>
        <Card>
          <CardHeader title="Edit Route"/>
          <CardBody>        
            <div className="mt-5">
              <Form onSubmit={editRouteHandler}>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Name</Form.Label>
                    <Form.Control ref={name} defaultValue={route.name} placeholder="Enter name" />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Status</Form.Label>
                    <Form.Control as="select" ref={status} onChange={handleChange} value={fields.status}>
                      <option value="true">Active</option>
                      <option value="false">In-Active</option>                  
                    </Form.Control>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Start Point</Form.Label>
                    <Form.Control as="select" ref={start_point} onChange={handleStartPointChange} value={fields.start_point}>
                      <option key='' value=''> Select Start Point </option>
                      {startPoints.map((startPoint) => (
                        <option key={startPoint.id} value={startPoint.uuid}>
                          {startPoint.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>End Point</Form.Label>
                    <Form.Control as="select" ref={end_point} onChange={handleEndPointChange} value={fields.end_point}>
                      <option key='' value=''>Select End Point</option>
                      {endPoints.map((endPoint) => (
                        <option key={endPoint.id} value={endPoint.uuid}>
                          {endPoint.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Distance</Form.Label>
                    <Form.Control ref={approximate_distance} defaultValue={route.distance} placeholder="Enter distance" type="number" min="0"/>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Row>
                      <Form.Group as={Col}>
                        <Form.Label>Approximate Time(Hours)</Form.Label>
                        <Form.Control ref={approximate_time_hours} defaultValue={Math.floor(parseInt(route?.approximateTime) / 60)} />
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>Approximate Time(Minutes)</Form.Label>
                        <Form.Control ref={approximate_time_minutes} defaultValue={Math.floor(parseInt(route?.approximateTime) % 60)} />
                      </Form.Group>
                    </Form.Row>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Departure Sequence Number</Form.Label>
                    <Form.Control ref={departure_sequence_number} defaultValue={route.departureSequenceNumber} placeholder="Departure Sequence Number" type="number" min="0"/>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Arrival Sequence</Form.Label>
                    <Form.Control ref={arrival_sequence_number} defaultValue={route.arrivalSequenceNumber} placeholder="Enter Arrival Sequence Number" type="number" min="0"/>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Description</Form.Label>
                    <Form.Control as="textarea" ref={description} defaultValue={route.description} placeholder="Enter description" />
                  </Form.Group>
                </Form.Row>
                <Card>
                  <CardHeader title="Sub Routes">
                    <CardHeaderToolbar>
                      <Button variant="primary" color="primary" className={[classes.button, "float-right"]} onClick={handCreateSubRoute}><Add /></Button>
                    </CardHeaderToolbar>
                  </CardHeader>
                  <CardBody>              
                    <table id="sub_route_table" className="sharearide_datatable">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Start Point</th>
                          <th>End Point</th>
                          <th>Dist(Km)</th>
                          <th>Approx. Time(Mins)</th>
                          <th>Dep. Seq #</th>
                          <th>Arr. Seq #</th>
                          <th>Default?</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {subRoutes.length > 0 ? (
                          subRoutes.map( (subRoute, index) => {                            
                            return (
                              <tr key={subRoute.uuid}>
                                <td><input type='text' className='form-control' value={subRoute.name} onChange={(e) => onNameChange(subRoute.uuid, e.target.value)}/></td>
                                <td>
                                  <Form.Control as="select" className="starting" onChange={(e) => onStartPointChange(subRoute.uuid, e.target.value)} value={subRoute.starting}>
                                    <option key='' value=''>Select Start Point</option>
                                    {startPoints.map((startPoint) => {
                                      return(<option key={startPoint.id} value={startPoint.uuid} >{startPoint.name}</option>)
                                    })}
                                  </Form.Control>
                                </td>
                                <td>
                                  <Form.Control as="select" className="ending" onChange={(e) => onEndpointChange(subRoute.uuid, e.target.value)} value={subRoute.ending}>
                                    <option key='' value=''>Select End Point</option>
                                    {endPoints.map((endPoint) => {
                                      return(<option key={endPoint.id} value={endPoint.uuid} >{endPoint.name}</option>)
                                    })}
                                  </Form.Control>
                                </td>
                                <td><input type='number' min={0} className='form-control' placeholder='' value={subRoute.distance}  onChange={(e) => onDistanceChange(subRoute.uuid, e.target.value)}/></td>
                                <td><input type='number' min={0} className='form-control' placeholder='0' value={subRoute.approximateTime}  onChange={(e) => onApproximateTimeChange(subRoute.uuid, e.target.value)}/></td>
                                <td><input type='number' min={0} className='form-control' placeholder='0' value={subRoute.departureSequenceNumber}  onChange={(e) => {
                                  var index = subRoutes.findIndex(obj => obj.uuid === subRoute.uuid);
                                  subRoutes[index].departureSequenceNumber = e.target.value;
                                  setSubRoutes([...subRoutes]);
                                }}/></td>
                                <td><input type='number' min={0} className='form-control' placeholder='0' value={subRoute.arrivalSequenceNumber}  onChange={(e) => {
                                  var index = subRoutes.findIndex(obj => obj.uuid === subRoute.uuid);
                                  subRoutes[index].arrivalSequenceNumber = e.target.value;
                                  setSubRoutes([...subRoutes]);
                                }}/></td>
                                <td>
                                  <Form.Control as="select" className="isdefault" onChange={(e) => onIsDefaultChange(subRoute.uuid, e.target.value)} value={subRoute.isDefault}>
                                    <option key='1' value='true'>Yes</option>
                                    <option key='2' value='false'>No</option>
                                  </Form.Control>
                                </td>
                                <td>
                                  <a onClick={() => handleRowDel(subRoute)} key={index} className="btn btn-icon btn-light btn-hover-danger btn-sm">
                                    <span className="svg-icon svg-icon-md svg-icon-danger"><SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} /></span>
                                  </a>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={8} align="center">No Sub routes</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </CardBody>
                </Card>          
                <Button variant="primary" className="float-right" type="submit">Save</Button>
              </Form>             
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}