import React, {useEffect, useState} from "react";
import $ from "jquery";
import {Link} from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router';
import { toAbsoluteUrl } from "../../../_helpers";
import SVG from "react-inlinesvg";
import alertify from "alertifyjs";
import { connect } from "react-redux";
import { LOCAL_STORAGE_CUSTOMER_DATA_KEY } from "../constants";
import { LoaderScreen } from '../../modules/Loader';
import { getCityLists, removeCityDetails } from "../../../app/sagas/routeManagement";

const CityList = ({
	user,
}) =>{
	const currentUser = user ? user : {};
	const [city, setCity] = useState(null);
	const [show, setShow] = useState(false);
	const [loading, setLoading] = useState(true);
	const [deleting, setDeleting] = useState(false);
	const history = useHistory();
	const [offset, setOffset] = useState(0);
	const [pageLimit, setPageLimit] = useState(100);
	const [totalPages, setTotalPages] = useState(0);
	const [cities, setCities] = useState([]);
	const setCurrentCity = (id) => {
	    setCity(id);
	    setShow(true);
	};
	
	useEffect(() => {
		const fetchData = async () => {
			try {
				const apiResponse = await getCityLists(offset, pageLimit);
				const { data = [], success } = apiResponse?.data;
				if (success) {
				  setCities(data);
				}
			} catch (error) {
				alertify.error(error.message);
			}
		};
		fetchData();
	}, [offset, pageLimit]);

	useEffect(() => {
		if(cities.length > 0){
			$("#city_table").DataTable({responsive: true, stateSave: true});
		}
		setLoading(false)
	}, [cities]);

  	const deleteCity = async() => {		
		try {
			setDeleting(true)
			const apiResponse = await removeCityDetails(city, currentUser.userId);
			const { success, errors = [] } = apiResponse?.data;
			if(success){
			  alertify.success("Record removed successfully");
			  history.go(0);
			} else{
			  alertify.error(errors[0].errorMsg);
			}
		} catch (error) {
			alertify.error(error.message);
		} finally {
			setShow(false);
			setDeleting(false);
		}			
  	}

	return (
		loading ? <LoaderScreen /> :
		<>
			<table id="city_table" className="sharearide_datatable">
				<thead>
					<tr>
						<th>ID</th>
						<th>Name</th>
						<th>Short Name</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{cities.length > 0 ? (
						cities.map((city, index) => (
							<tr key={city.id}>
								<td>{city.id}</td>
								<td>{city.name}</td>
								<td>{city.shortName}</td>
								<td>
									<Link to={`/city/edit/${city.uuid}`} className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
										<span className="svg-icon svg-icon-md svg-icon-primary">
										<SVG
											src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
										/>
										</span>
									</Link>
									<a 
									onClick={() => setCurrentCity(city.uuid)}
									key={index}
									className="btn btn-icon btn-light btn-hover-danger btn-sm">
										<span className="svg-icon svg-icon-md svg-icon-danger">
										<SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
										</span>
									</a>
								</td>							
							</tr>
						))
					) : (
						<tr><td colSpan={5}>No Records</td></tr>
					)}
				</tbody>
			</table>
		  	<Modal show={show} onHide={() => setShow(false)} backdrop="static">
				<Modal.Header closeButton>
				<Modal.Title>Delete City</Modal.Title>
				</Modal.Header>
				<Modal.Body>Are you sure you want to Delete?</Modal.Body>
				<Modal.Footer>
				<Button variant="secondary" onClick={() => setShow(false)} disabled={ deleting }>
					Cancel
				</Button>
				<Button variant="primary" onClick={deleteCity} disabled={ deleting }>
				<span>Yes</span>
                  {deleting && <span className="ml-2 spinner spinner-white"></span>}&nbsp;&nbsp;
				</Button>
				</Modal.Footer>
			</Modal>
	    </>
	)
}

function mapStateToProps(state) {
	return {
	  user: state?.Auth?.user || window.localStorage.getItem(LOCAL_STORAGE_CUSTOMER_DATA_KEY),
	}
}
  
export default connect(mapStateToProps, null)(CityList);