import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import alertify from "alertifyjs";
import {API_URL, busManagement} from '../constants.js';
import { Card, CardBody, CardHeader,} from "../../../_metronic/_partials/controls";
import Select from 'react-select';
import { useSelector } from "react-redux";
import { LoaderScreen } from '../../modules/Loader';
import { getAgent, getOperators, updateAgent } from "../../../app/sagas/userManagement";
import { getBusList } from "../../../app/sagas/busManagement";

export function EditAgent(props) {

  const [loading, setLoading] = useState(false);
  const { userId, role } = useSelector((state) => state.Auth.user);
	const initialState = {
		agent: '',
		status: '',
    associated_partner: ''
	};

	const [agent, setAgent] = useState({});
	const [fields, setFields] = useState(initialState);
  const [partners, setPartners] = useState([]);
  const [partnerInfo, setPartner] = useState({});
  const [buses, setBuses] = useState([]);
  const partner = useRef();
  const names = useRef();
  const email = useRef();
  const username = useRef();
  const status = useRef();
  const id = props.match.params.id;

  const selectedBuses = useRef();
  const [inputValue, setInputValue] = useState('');
  const [menuIsOpen, setMenuIsOpen] = useState('');
  const [assignedBuses, assignBuses] = useState([]);

  const onPartnerChange = async(ref) =>{
    setFields({
      associated_partner: ref
    });
    
    buses.splice(0, buses.length);
    setBuses([...buses]);
    const apiResponse = await getBusList(`bus/all/${ref}/operator/0/200`);
		const { data = [], success } = apiResponse?.data;
		if (success) {
      for(var i = 0; i < data.length; i++){
        buses.push({
          label: data[i].Bus.regNo,
          value: data[i].Bus.uuid
        });
      }
			setBuses([...buses]);
		}
  }

  const focus = () => {
    setMenuIsOpen(true);
  };

  const onInputChange = (value, { action }) => {
    switch (action) {
      case 'input-change':
        setInputValue(value);
        return;
      case 'menu-close':
        let menuIsOpen = undefined;
        if (inputValue) {
          menuIsOpen = true;
        }
        setMenuIsOpen(menuIsOpen);
        return;
      default:
        return;
    }
  };

  const handleAssignedBusesChange = (e) => {
    assignedBuses.splice(0, assignedBuses.length);
    var options = e;
    if(options != undefined || options != null){
      for (var i = 0; i < options.length; i++) {
        assignedBuses.push(options[i]);
      }
    }
    assignBuses([...assignedBuses]);
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiResponse = await getAgent( id );
        const { success, data = {}, errors = [] } = apiResponse?.data;
        if(success){
          setFields({
            status: data.agent.status,
            associated_partner: data.agent.busOperator
          });
          for(var i = 0; i < data.associatedBuses.length; i++){
            assignedBuses.push({
              label: data.associatedBuses[i].bus.regNo,
              value: data.associatedBuses[i].bus.uuid
            });
          }
          assignBuses([...assignedBuses]);
          setAgent(data);
        } else{
          alertify.error(errors[0].errorMsg);
        }
      } catch (error) {
        alertify.error(error.message);
      }
		};
		fetchData();
	}, [id]);

  useEffect(() => {
    if(agent?.agent?.busOperator){
      const fetchData = async () => {
        try {
          const apiResponse = await getBusList( `bus/all/${agent.agent.busOperator}/operator/0/200` );
          const { success, data = {}, errors = [] } = apiResponse?.data;          
          if(success){
            for(var i = 0; i < data.length; i++){
              buses.push({
                label: data[i].Bus.regNo,
                value: data[i].Bus.uuid
              });
            }
            setBuses([...buses]);
          } else{
            alertify.error(errors[0].errorMsg);
          }
        } catch (error) {
          alertify.error(error.message);
        }
      };
      fetchData();
    }
	}, [agent]);

  useEffect(() => {
    const fetchData = async () => {
      if(role == 'super'){
        try {
          const apiResponse = await getOperators(0, 2000);
          const { data = [], success } = apiResponse?.data;
          if (success) {
            setPartners(data);
          }
        } catch (error) {
          alertify.error(error.message);
        } finally {
          setLoading(false);
        }
      } else{
        try {
          axios.get(`${API_URL}/app/v1/user/${userId}`).then( (response) => {
            const { success, errors = [], data, message } = response?.data;
            if(success){
              var ref = data.user.busOperator         
              axios.get(`${API_URL}${busManagement}/operator/${ref}`).then( (res) => {
                const { success, errors = [], data, message } = res?.data;
                if(success){
                  setPartner(data);              
                } else {  
                  alertify.error(errors[0]?.errorMsg || message);
                  return null;
                }
              });
            } else {  
              alertify.error(errors[0]?.errorMsg || message);
              return null;
            }
          });
        } catch (error) {
          alertify.error(error.message);
        } finally {
          setLoading(false);
        }
      }
		};
		fetchData();
	}, []);
	
	const handleChange = (e) => {
		setFields({status: e.target.value});
	}

  const editAgentHandler = async(e) => {
  	e.preventDefault();
    setLoading(true);
    try {
      const apiResponse = await updateAgent( {
        "agent":{
          "id": agent.agent.id,
          "uuid": agent.agent.uuid,
          "name": names.current.value, 
          "image": null,
          "order": 0, 
          "status": status.current.value, 
          "createdBy": agent.agent.createdBy,
          "updatedBy": agent.agent.updatedBy,
          "deleted": agent.agent.deleted,
          "busOperator": role == 'super' ? fields.associated_partner : partnerInfo.uuid
        },
        "assignedBuses": assignedBuses
      }, userId);
      const { success, errors = [] } = apiResponse?.data;
      if(success){
        alertify.success("Record updated successfully");
        props.history.push("/agent/list");
      } else{
        alertify.error(errors[0].errorMsg);
      }
    } catch (error) {
      alertify.error(error.message);
    } finally {
      setLoading(false);
    }
  }
  return (
    loading ? <LoaderScreen /> :
    <Row>
      <Col md={12}>
        <Card>
          <CardHeader title="Edit Agent"/>
          <CardBody>        
            <div className="mt-5">
              <Form onSubmit={editAgentHandler}>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Names</Form.Label>
                    <Form.Control ref={names} defaultValue={agent.agent != undefined ? agent.agent.name : ""} placeholder="Enter full name" />
                  </Form.Group>
                  {role == 'super' ?
                    <Form.Group as={Col}>
                      <Form.Label>Associated Partner</Form.Label>
                      <Form.Control as="select" ref={partner} value={fields.associated_partner} onChange={(e) => onPartnerChange(e.target.value)}>
                        <option key='' value=''>Select Partner</option>
                        {partners.map((partner) => (
                          <option key={partner.uuid} value={partner.uuid}>{partner.name}</option>
                        ))}
                      </Form.Control>
                    </Form.Group>:
                    <Form.Group as={Col}>
                      <Form.Label>Associated Partner</Form.Label>
                      <Form.Control value={partnerInfo.name || ""}/>
                    </Form.Group>
                  }
                  <Form.Group as={Col}>
                    <Form.Label>Bus Assigned</Form.Label>
                    <Select ref={selectedBuses} onFocus={focus} isMulti isClearable isSearchable onInputChange={onInputChange} name="buses" options={buses} 
                      menuIsOpen={menuIsOpen} onChange={handleAssignedBusesChange} value={assignedBuses}/>
                  </Form.Group> 
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Email</Form.Label>
                    <Form.Control style={{background: '#D3D3D3'}} ref={email} readOnly={true} defaultValue={agent.authDetails != undefined ? agent.authDetails.email : ""} placeholder="Enter email" />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Username</Form.Label>
                    <Form.Control style={{background: '#D3D3D3'}} ref={username} readOnly={true} defaultValue={agent.authDetails != undefined ? agent.authDetails.username : ""} placeholder="Enter username" />
                  </Form.Group>
                  <Form.Group as={Col}>
                  <Form.Label>Password</Form.Label>
                    <Form.Control style={{background: '#D3D3D3'}} type="password" readOnly={true} placeholder="********" />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Status</Form.Label>
                    <Form.Control as="select" ref={status} onChange={handleChange} value={fields.status}>
                      <option value="Active">Active</option>
                      <option value="Inactive">In-Active</option>               
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col}/>
                  <Form.Group as={Col}/>
                </Form.Row>
                <Button variant="primary" className="float-right" type="submit">Save</Button>
              </Form>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}