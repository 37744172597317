import axios from "../../config/axiosConfig";
import { events } from "../actions";
import { onRequestError, onCompleteRequest, formatRequest, callRestricatedAPI, } from "./common";
import { GENERIC_ERROR_CODE, } from "../pages/constants";

const { REACT_APP_API_VERSION } = process.env;

export function createNewCityDetails(data, userId) {
  return axios(
    formatRequest(
      `/api/app/${REACT_APP_API_VERSION}/city/create/${userId}`,
      "post",
      data,
      true,
      true,
      1000 * 60 * 5 // 5 minutes as per 7323
    )
  );
}
export function getCityLists(offset, limit) {
  return axios(
    formatRequest(
      `/api/app/${REACT_APP_API_VERSION}/city/all/${offset}/${limit}`,
      "get",
      null,
      true,
      true
    )
  );
}
export function getCityDetails(cityId) {
  return axios(
    formatRequest(
      `/api/app/${REACT_APP_API_VERSION}/city/${cityId}`,
      "get",
      null,
      true,
      true
    )
  );
}
export function updateCityDetails(data, userId) {
  return axios(
    formatRequest(
      `/api/app/${REACT_APP_API_VERSION}/city/${userId}/edit`,
      "put",
      data,
      true,
      true
    )
  );
}
export function removeCityDetails(cityId, userId) {
  return axios(
    formatRequest(
      `/api/app/${REACT_APP_API_VERSION}/city/${userId}/${cityId}`,
      "delete",
      undefined,
      true,
      true
    )
  );
}
export function createNewTerminal(data, userId) {
  return axios(
    formatRequest(
      `/api/app/${REACT_APP_API_VERSION}/terminal/create/${userId}`,
      "post",
      data,
      true,
      true,
      1000 * 60 * 5 // 5 minutes as per 7323
    )
  );
}
export function getTerminalLists(offset, limit) {
  return axios(
    formatRequest(
      `/api/app/${REACT_APP_API_VERSION}/terminal/all/${offset}/${limit}`,
      "get",
      null,
      true,
      true
    )
  );
}
export function removeTerminalDetails(terminalId, userId) {
  return axios(
    formatRequest(
      `/api/app/${REACT_APP_API_VERSION}/terminal/${userId}/${terminalId}`,
      "delete",
      undefined,
      true,
      true
    )
  );
}
export function getTerminalDetails(terminalId) {
  return axios(
    formatRequest(
      `/api/app/${REACT_APP_API_VERSION}/terminal/${terminalId}`,
      "get",
      null,
      true,
      true
    )
  );
}
export function updateTerminalDetails(data, userId) {
  return axios(
    formatRequest(
      `/api/app/${REACT_APP_API_VERSION}/terminal/${userId}/edit`,
      "put",
      data,
      true,
      true
    )
  );
}

export function* fetchCitiesRecords(action) {
  try {
    const apiResponse = yield callRestricatedAPI(
      getCityLists, 
      action.offset,
      action.limit,
    );
    
    const { data = [], errors = [] } = apiResponse;
    if (data?.rows) {
      yield onCompleteRequest(
        events.routesManagement.city.FETCH_CITY_LIST_SUCCESS,
        data?.rows?.length ? data.rows : []
      );
    } else {
      const errorObject = errors.length > 0 ? errors[0] : {};
      const errorCode = errorObject.errorCode || GENERIC_ERROR_CODE;
      const errorMsgFromBE = errorObject.errorMsg || "";
      yield onRequestError(
        events.routesManagement.city.FETCH_CITY_LIST_FAILED,
        errorCode,
        errorMsgFromBE
      );
    }
  } catch (e) {
    yield onRequestError(
      events.routesManagement.city.FETCH_CITY_LIST_FAILED,
      GENERIC_ERROR_CODE,
      e.message
    );
  }
}

export function createNewRoute(data, userId) {
  return axios(
    formatRequest(
      `/api/app/${REACT_APP_API_VERSION}/routes/create/${userId}`,
      "post",
      data,
      true,
      true,
      1000 * 60 * 5 // 5 minutes as per 7323
    )
  );
}
export function getRouteLists(offset, limit) {
  return axios(
    formatRequest(
      `/api/app/${REACT_APP_API_VERSION}/routes/all/${offset}/${limit}`,
      "get",
      null,
      true,
      true
    )
  );
}
export function removeRouteDetails(routeId, userId) {
  return axios(
    formatRequest(
      `/api/app/${REACT_APP_API_VERSION}/routes/${userId}/${routeId}`,
      "delete",
      undefined,
      true,
      true
    )
  );
}
export function getRouteDetails(routeId) {
  return axios(
    formatRequest(
      `/api/app/${REACT_APP_API_VERSION}/routes/${routeId}`,
      "get",
      null,
      true,
      true
    )
  );
}
export function updateRouteDetails(data, userId) {
  return axios(
    formatRequest(
      `/api/app/${REACT_APP_API_VERSION}/routes/${userId}/edit`,
      "put",
      data,
      true,
      true
    )
  );
}
export function fetchRouteByCities(startCity, endCity) {
  return axios(
    formatRequest(
      `/api/app/${REACT_APP_API_VERSION}/routes/${startCity}/${endCity}/by-cities`,
      "get",
      null,
      true,
      true
    )
  );
}

export function getSubRouteList(routeId) {
  return axios(
    formatRequest(
      `/api/app/${REACT_APP_API_VERSION}/routes/sub-route/route/${routeId}`,
      "get",
      null,
      true,
      true
    )
  );
}

