import React, { useRef, useState, } from "react";
import Spinner from 'react-bootstrap/Spinner'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import alertify from "alertifyjs";
import { Card, CardBody, CardHeader, } from "../../../_metronic/_partials/controls";
import { useSelector } from "react-redux";
import { createNewCityDetails } from "../../../app/sagas/routeManagement";

export function CreateCity(props) {

  const [loading, setLoading] = useState(false);
  const { userId } = useSelector((state) => state.Auth.user);

  const name = useRef();
  const short_name = useRef();

  const createCityHandler = async (e) => {
    e.preventDefault();
    if(name.current.value === "" || short_name.current.value === ""){
      alertify.error("All fields are required");
    } else{    
      setLoading(true);
      try {
        const apiResponse = await createNewCityDetails( {
          "city":{
            "id": null,
            "uuid": null,
            "name": name.current.value,
            "shortName": short_name.current.value,
            "order": 1,
            "status": true,
            "createdBy": userId,
            "updatedBy": userId,
            "deleted": false
          }
        }, userId);
        const { success, data = {}, errors = [] } = apiResponse?.data;
        if(success){
          alertify.success("City record added successfully");
          props.history.push("/city/list");
        } else{
          alertify.error(errors[0].errorMsg);
        }
      } catch (error) {
        alertify.error(error.message);
      } finally {
        setLoading(false);
      }
    }
  }
  return (
    loading ?
    <div style={{opacity: 0.8, backgroundColor:'#ccc', position: 'fixed', width:'100%', height:'100%', top:'0px', left:'0px', zIndex:1000}}>
      <div style={{ position: 'absolute', left: '60%', top: '50%', transform: 'translate(-60%, -50%)' }} >
        <Spinner animation="grow" variant='warning' />
      </div>
    </div>: 
    <Row>
      <Col md={12}>                
        <Card>
          <CardHeader title="Add New City"/>
          <CardBody>          
            <div className="mt-5">
              <Form onSubmit={createCityHandler}>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Name</Form.Label>
                    <Form.Control ref={name} placeholder="Enter name" />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Short Name</Form.Label>
                    <Form.Control ref={short_name} placeholder="Enter short name" />
                  </Form.Group>
                </Form.Row>
                <Button variant="primary" className="float-right" type="submit">
                  Save
                </Button>
              </Form>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}