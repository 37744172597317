import React, {useEffect, useState} from "react";
import $ from "jquery";
import {Link} from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router';
import { toAbsoluteUrl } from "../../../_helpers";
import SVG from "react-inlinesvg";
import alertify from "alertifyjs";
import { useSelector } from "react-redux";
import { LoaderScreen } from '../../modules/Loader';
import { getBusTypeLists, removeBusTypeDetails } from "../../../app/sagas/busManagement";

export function BusTypeList(props){

	const [ loading, setLoading ] = useState(true);
  	const { user } = useSelector((state) => state.Auth);
	const currentUser = user ? user : {};
	const [deleting, setDeleting] = useState(false);

	const [busTypes, setBusTypes] = useState([]);
	const [busType, setBusType] = useState(null);
	const [show, setShow] = useState(false);
	const history = useHistory();
	const [offset, setOffset] = useState(0);
	const [pageLimit, setPageLimit] = useState(2000);
	const [totalPages, setTotalPages] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
			try{
				const apiResponse = await getBusTypeLists(offset, pageLimit);
				const { data = [], success } = apiResponse?.data;
				if (success) {
					setBusTypes(data);
				}
			}catch{}
		};
		fetchData();
	}, [offset, pageLimit]);
	
	useEffect(() => {
		if(busTypes.length > 0){
			$("#bus_type_table").DataTable({responsive: true, stateSave: true});
		}
		setLoading(false);
	}, [busTypes]);

	const setCurrentBusType = (id) => {
	    setBusType(id);
	    setShow(true);
	};

  	const deleteBusType = async() => {
		try {
			setDeleting(true);
			const apiResponse = await removeBusTypeDetails(busType, currentUser.userId);
			const { success, errors = [], message } = apiResponse?.data;
			if(success){
			  alertify.success("Record removed successfully");
			  history.go(0);
			} else{
			  alertify.error(errors[0]?.errorMsg || message);
			}
		} catch (error) {
			alertify.error(error.message);
		} finally {
			setShow(false);
			setDeleting(false);
		}
  	}

	return (
		loading ? <LoaderScreen /> :
		<>
			<table id="bus_type_table" className="sharearide_datatable">
				<thead>
				<tr>
					<th>ID</th>
					<th>Name</th>
					<th>Actions</th>
				</tr>
				</thead>
				<tbody>
					{busTypes.length > 0 ? (
						busTypes.map((busType, index) => (
							<tr key={busType.id}>
								<td>{busType.id}</td>
								<td>{busType.name}</td>
								<td>
									<Link to={`/bus-type/edit/${busType.uuid}`} className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
										<span className="svg-icon svg-icon-md svg-icon-primary">
											<SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")} />
										</span>
									</Link>
									<a onClick={() => setCurrentBusType(busType.uuid)} key={index} className="btn btn-icon btn-light btn-hover-danger btn-sm">
										<span className="svg-icon svg-icon-md svg-icon-danger"><SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} /></span>
									</a>
								</td>		            
							</tr>
						))
					) : (
						<tr>
							<td colSpan={3}>No Bus Type</td>
						</tr>
					)}
				</tbody>
			</table>
		  	<Modal show={show} onHide={() => setShow(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Delete Bus Type</Modal.Title>
				</Modal.Header>
	        	<Modal.Body>Are you sure you want to Delete?</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShow(false)} disabled={ deleting }>
						Cancel
					</Button>
					<Button variant="primary" onClick={deleteBusType} disabled={ deleting }>
						<span>Yes</span>{deleting && <span className="ml-2 spinner spinner-white"></span>}&nbsp;&nbsp;
					</Button>
				</Modal.Footer>
	      	</Modal>
	    </>
	)
}