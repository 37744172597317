import React, {useEffect, useState} from "react";
import $ from "jquery";
import {Link} from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router';
import { toAbsoluteUrl } from "../../../_helpers";
import SVG from "react-inlinesvg";
import alertify from "alertifyjs";
import { LoaderScreen } from '../../modules/Loader';
import { getTerminalLists, removeTerminalDetails } from "../../../app/sagas/routeManagement";
import { connect } from "react-redux";
import { LOCAL_STORAGE_CUSTOMER_DATA_KEY } from "../constants";

const TerminalList = ({
	user,
})=>{
	const currentUser = user ? user : {};
	const [terminal, setTerminal] = useState(null);
	const [show, setShow] = useState(false);
	const [loading, setLoading] = useState(true);
	const [deleting, setDeleting] = useState(false);
	const history = useHistory();
	const [terminals, setTerminals] = useState([]);
	const [offset, setOffset] = useState(0);
	const [pageLimit, setPageLimit] = useState(2000);
	const [totalPages, setTotalPages] = useState(null);
	
	useEffect(() => {
		const fetchData = async () => {
			const apiResponse = await getTerminalLists(offset, pageLimit);
			const { data = [], success } = apiResponse?.data;
			if (success) {
				setTerminals(data);
			}
		};
		fetchData();
	}, []);

	useEffect(() => {
		if(terminals.length > 0){
			$("#terminal_table").DataTable({responsive: true, stateSave: true});			
			setTotalPages(terminals.length);
			setOffset(terminals.length + 1);
			setPageLimit(terminals.length + 100);
		}
		setLoading(false);
	}, [terminals]);

	const setCurrentTerminal = (id) => {
	    setTerminal(id);
	    setShow(true);
	};

  	const deleteTerminal = async() => {
		try {
			setDeleting(true)
			const apiResponse = await removeTerminalDetails(terminal, currentUser.userId);
			const { success, errors = [] } = apiResponse?.data;
			if(success){
			  alertify.success("Record removed successfully");
			  history.go(0);
			} else{
			  alertify.error(errors[0].errorMsg);
			}
		} catch (error) {
			alertify.error(error.message);
		} finally {
			setShow(false);
			setDeleting(false);
		}		
  	}

	return (
		loading ? <LoaderScreen /> :
		<>
			<table id="terminal_table" className="sharearide_datatable">
				<thead>
					<tr>
						<th>ID</th>
						<th>City</th>
						<th>Name</th>
						<th>Latitude</th>
						<th>Longitude</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{terminals.length > 0 ? (
						terminals.map((obj, index) => (
							<tr key={obj?.uuid}>
								<td>{obj?.id}</td>
								<td>{obj?.City?.name}</td>
								<td>{obj.name}</td>
								<td>{obj?.latitude}</td>
								<td>{obj?.longitude}</td>
								<td>
									<Link to={`/terminal/edit/${obj.uuid}`} className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
										<span className="svg-icon svg-icon-md svg-icon-primary">
											<SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}/>
										</span>
									</Link>
									<a onClick={() => setCurrentTerminal(obj.uuid, index)} key={index} className="btn btn-icon btn-light btn-hover-danger btn-sm">
										<span className="svg-icon svg-icon-md svg-icon-danger"> <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} /> </span>
									</a>
								</td>		            
							</tr>
						))
		      		) : (
						<tr>
							<td colSpan={3}>No Terminal</td>
						</tr>
		      		)}
		    	</tbody>
		  	</table>
		  	<Modal show={show} onHide={() => setShow(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Delete Terminal</Modal.Title>
				</Modal.Header>
	        	<Modal.Body>Are you sure you want to Delete?</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShow(false)} disabled={ deleting }>Cancel</Button>
					<Button variant="primary" onClick={deleteTerminal} disabled={ deleting }>
					<span>Yes</span>{deleting && <span className="ml-2 spinner spinner-white"></span>}&nbsp;&nbsp;
					</Button>
				</Modal.Footer>
	      	</Modal>
	    </>
	)
}

function mapStateToProps(state) {
	return {
	  user: state?.Auth?.user || window.localStorage.getItem(LOCAL_STORAGE_CUSTOMER_DATA_KEY),
	}
}
  
export default connect(mapStateToProps, null)(TerminalList);