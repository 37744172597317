import React, { useEffect, useState, useRef } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import alertify from "alertifyjs";
import { Card, CardBody, CardHeader,} from "../../../_metronic/_partials/controls";
import Select from 'react-select';
import { useSelector } from "react-redux";
import { LoaderScreen } from '../../modules/Loader';
import { getBusTypeLists, getBusFacilityLists, createBus } from "../../../app/sagas/busManagement";
import { getOperators } from "../../../app/sagas/userManagement";

export function CreateBus(props) {

  const [loading, setLoading] = useState(true);
  const { userId } = useSelector((state) => state.Auth.user);
  const reg_no = useRef();
  const engine_no = useRef();
  const model_no = useRef();
  const chasis_no = useRef();
  const no_of_rows = useRef();
  const lastseat = useRef();
  const total_seats = useRef();
  const company = useRef();
  const owner = useRef();
  const layout = useRef();
  const bus_type_id = useRef();
  const status = useRef();
  const partner_id = useRef();
  const selectedFacilities = useRef();
  const [busTypes, setBusTypes] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [partners, setPartners] = useState([]);
  const [image, setImage] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [menuIsOpen, setMenuIsOpen] = useState('');
  const [allowedFacilities, setAllowedFacilities] = useState([]);

  useEffect(() => {
		const fetchData = async () => {
			try {
        const busTypeApiResponse = await getBusTypeLists(0, 100);
        if (busTypeApiResponse?.data.success) {
          setBusTypes(busTypeApiResponse?.data.data);
        }
        const busApiResponse = await getBusFacilityLists(0, 500);
        if (busApiResponse?.data.success) {
          setFacilities(busApiResponse?.data.data);
        }
				const apiResponse = await getOperators(0, 500);
				const { data = [], success } = apiResponse?.data;
				if (success) {
					setPartners(data);
				}
			} catch (error) {
				alertify.error(error.message);
			} finally {
				setLoading(false);
			}
		};
		fetchData();
	}, []);

  const focus = () => {
    setMenuIsOpen(true);
  };

  const onInputChange = (value, { action }) => {
    switch (action) {
      case 'input-change':
        setInputValue(value);
        return;
      case 'menu-close':
        let menuIsOpen = undefined;
        if (inputValue) {
          menuIsOpen = true;
        }
        setMenuIsOpen(menuIsOpen);
        return;
      default:
        return;
    }
  };

  const handleAssignedFacilitiesChange = (e) => {
    allowedFacilities.splice(0, allowedFacilities.length);
    var options = e;
    if(options != undefined || options != null){
      for (var i = 0; i < options.length; i++) {
        allowedFacilities.push(options[i]);
      }
    }
    setAllowedFacilities([...allowedFacilities]);
  }

  const  handleImage = (e) =>{
    var reader = new FileReader(); 
    reader.onload = (e) => {
      setImage(e.target.result);
    };
    reader.readAsDataURL(e.target.files[0]);
  }

  const handleTotalSeats = (e) =>{
    var bus_layout = layout.current.value;
    bus_layout = bus_layout.split("/");
    var seats_per_row = parseInt(bus_layout[0]) + parseInt(bus_layout[1]);
    if(no_of_rows.current.value !== ''){
      var total_bus_seats = seats_per_row * no_of_rows.current.value;      
      if(lastseat.current.value === 'true'){
        total_seats.current.value = total_bus_seats + 1;
      }else{
        total_seats.current.value = total_bus_seats;
      }
    }
  }

  const createBusHandler = async(e) => {
  	e.preventDefault();
    setLoading(true);
    try {
      const apiResponse = await createBus( {
        "bus":{
          "id": null,
          "uuid": null,
          "regNo": reg_no.current.value,
          "engineNo": engine_no.current.value,
          "modelNo": model_no.current.value,
          "chasisNo": chasis_no.current.value,
          "layout": layout.current.value,
          "noOfRows": no_of_rows.current.value,
          "totalSeats": total_seats.current.value,
          "lastseat": lastseat.current.value,
          "owner": owner.current.value,
          "company": company.current.value,
          "allowedTime": null,
          "image": image,
          "status": status.current.value,
          "createdBy": userId,
          "updatedBy": userId,
          "deleted": false,
          "busTypeId": bus_type_id.current.value,
          "partnerId": partner_id.current.value,
        },
        "facilities": allowedFacilities
      }, userId);
      const { success, errors = [] } = apiResponse?.data;
      if(success){
        alertify.success("Record added successfully");
        props.history.push("/bus/list");
      } else{
        alertify.error(errors[0].errorMsg);
      }
    } catch (error) {
      alertify.error(error.message);
    } finally {
      setLoading(false);
    }
  }
  return (
    loading ? <LoaderScreen /> :
    <Row>
      <Col md={12}>
        <Card>
          <CardHeader title="Add New Bus"/>
          <CardBody>
            <div className="mt-5">
              <Form onSubmit={createBusHandler}>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Bus Type</Form.Label>
                    <Form.Control as="select" ref={bus_type_id}>
                      <option key='' value=''>Select Bus Type</option>
                      {busTypes.map((busType, key) => (
                        <option key={busType.uuid} value={busType.uuid}>{busType.name}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Status</Form.Label>
                    <Form.Control as="select" ref={status}>
                      <option value="true">Active</option>
                      <option value="false">In-Active</option>                  
                    </Form.Control>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Reg No</Form.Label>
                    <Form.Control ref={reg_no} placeholder="Enter Reg No" />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Engine No</Form.Label>
                    <Form.Control ref={engine_no} placeholder="Enter Engine No" />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Model No</Form.Label>
                    <Form.Control ref={model_no} placeholder="Enter Model No" />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Chasis No</Form.Label>
                    <Form.Control ref={chasis_no} placeholder="Enter Chasis No" />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Owner</Form.Label>
                    <Form.Control ref={owner} placeholder="Enter owner" />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Company</Form.Label>
                    <Form.Control ref={company} placeholder="Enter company" />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Layout</Form.Label>
                    <Form.Control as="select" ref={layout} onChange={handleTotalSeats}>
                      <option>1/1</option>
                      <option>1/2</option>
                      <option>1/3</option>
                      <option>1/2</option>
                      <option>1/4</option>
                      <option>2/1</option>
                      <option>2/2</option>
                      <option>2/3</option>
                      <option>2/4</option>
                      <option>3/1</option>
                      <option>3/2</option>
                      <option>3/3</option>
                      <option>3/4</option>
                      <option>4/1</option>
                      <option>4/2</option>
                      <option>4/3</option>
                      <option>4/4</option>                                    
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>No Of Rows</Form.Label>
                    <Form.Control ref={no_of_rows} onChange={handleTotalSeats} placeholder="Enter no of rows" />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Last Seat</Form.Label>
                    <Form.Control as="select" ref={lastseat} onChange={handleTotalSeats}>
                      <option value="false">No</option>
                      <option value="true">Yes</option>                      
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Total Seats</Form.Label>
                    <Form.Control ref={total_seats} disabled />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Partner</Form.Label>
                    <Form.Control as="select" ref={partner_id}>
                      <option key='' value=''>Select Partner</option>
                      {partners.map(function(partner, key) {
                        return(<option key={key} value={partner.uuid}>{partner.name}</option>)
                      }, this)}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Image</Form.Label>
                    <Row>
                      <Col>
                        <Form.Control type="file" onChange={handleImage} />
                      </Col>
                      <Col>
                        {image? <img width='100' src={image} alt=""/> : ''}
                      </Col>
                    </Row>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Available Facilities</Form.Label>
                    <Select ref={selectedFacilities} onFocus={focus} isMulti isClearable isSearchable onInputChange={onInputChange} name="facilities" options={facilities.map(data=>{
                      return {
                        label: data.name,
                        value: data.uuid
                      }
                    })} menuIsOpen={menuIsOpen} onChange={handleAssignedFacilitiesChange}/>
                  </Form.Group>
                  <Form.Group as={Col}></Form.Group>
                </Form.Row>
                <Button variant="primary" className="float-right" type="submit">
                  Save
                </Button>
              </Form>                
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}