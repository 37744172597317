import React, { useEffect, useState, useRef } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import alertify from "alertifyjs";
import Select from 'react-select';
import { Card, CardBody, CardHeader, } from "../../../_metronic/_partials/controls";
import { useSelector } from "react-redux";
import { LoaderScreen } from '../../modules/Loader';
import { getBusTypeLists, getBusFacilityLists, getBus, updateBus } from "../../../app/sagas/busManagement";
import { getOperators } from "../../../app/sagas/userManagement";

export function EditBus(props) {

  const [loading, setLoading] = useState(true);
  const { userId } = useSelector((state) => state.Auth.user);
	const [bus, setBus] = useState({});
	const [fields, setFields] = useState({
		bus: '',
    status: '',
    layout: '',
    lastseat: '',
    bus_type_id: '',
    partner_id: '',
	});
  const [busTypes, setBusTypes] = useState([]);
  const [partners, setPartners] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const reg_no = useRef();
  const engine_no = useRef();
  const model_no = useRef();
  const chasis_no = useRef();
  const no_of_rows = useRef();
  const lastseat = useRef();
  const total_seats = useRef();
  const company = useRef();
  const owner = useRef();
  const layout = useRef();
  const bus_type_id = useRef();
  const status = useRef();
  const partner_id = useRef();
  const selectedFacilities = useRef();
  const id = props.match.params.id;
  const [image, setImage] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [menuIsOpen, setMenuIsOpen] = useState('');
  const [allowedFacilities, setAllowedFacilities] = useState([]);

  const focus = () => {
    setMenuIsOpen(true);
  };

  const onInputChange = (value, { action }) => {
    switch (action) {
      case 'input-change':
        setInputValue(value);
        return;
      case 'menu-close':
        let menuIsOpen = undefined;
        if (inputValue) {
          menuIsOpen = true;
        }
        setMenuIsOpen(menuIsOpen);
        return;
      default:
        return;
    }
  };

  useEffect(() => { 
    setMenuIsOpen(false)   
    const fetchData = async () => {
      try {
        const busTypeApiResponse = await getBusTypeLists(0, 100);
        if (busTypeApiResponse?.data.success) {
          setBusTypes(busTypeApiResponse?.data.data);
        }
        const busFacilitiesApiResponse = await getBusFacilityLists(0, 500);
        if (busFacilitiesApiResponse?.data.success) {
          setFacilities(busFacilitiesApiResponse?.data.data.map(info=> {
            return {
              value: info.uuid, 
              label: info.name
            }
          }));
        }
        const busOperatorsApiResponse = await getOperators(0, 500);
				if (busOperatorsApiResponse?.data.success) {
					setPartners(busOperatorsApiResponse?.data.data);
				}
        const apiResponse = await getBus( id );
        const { success, data = {}, errors = [] } = apiResponse?.data;
        if(success){
          setFields({
            status: data.Bus.status,
            layout: data.Bus.layout,
            lastseat: data.Bus.lastseat,
            bus_type_id: data.Bus.busTypeId,
            image: data.Bus.image !== null ? new Buffer.from(data.Bus.image).toString("ascii") : null,
            partner_id: data.Bus.partnerId
          });
          setBus(data);
          const img = data.Bus.image !== null ? new Buffer.from(data.Bus.image).toString("ascii") : null;
          setImage(img);
          if(data.BusFacilities !== undefined || data.BusFacilities !== null){
            for(var i = 0; i < data.BusFacilities.length; i++){
              allowedFacilities.push({
                value: data?.BusFacilities[i]?.BusFacility?.facility,
                label: data?.BusFacilities[i]?.Facilities?.name
              });
            }
            setAllowedFacilities([...allowedFacilities]);
          }
        } else{
          alertify.error(errors[0].errorMsg);
        }
      } catch (error) {
        alertify.error(error.message);
      } finally {
        setLoading(false);
      }
		};
		fetchData();
	}, []);
	
	const handleChange = (e) => {
		setFields({status: e.target.value});
	}

  const handleLayoutChange = (e) => {
    var val = e.target.value;
    setFields(currentState =>({
      ...currentState,
      layout: val
    }));
    handleTotalSeats();
  }

  const handleLastseatChange = (e) => {
    var val = e.target.value
    setFields(currentState =>({
      ...currentState,
      lastseat: val
    }));
    handleTotalSeats();
  }

  const handleBusTypeChange = (e) => {
    setFields({bus_type_id: e.target.value});
  }

  const handleOnPartnerChange = (e) => {
    setFields({partner_id: e.target.value});
  }

  const handleTotalSeats = (e) =>{
    var bus_layout = layout.current.value;
    bus_layout = bus_layout.split("/");
    var seats_per_row = parseInt(bus_layout[0]) + parseInt(bus_layout[1]);
    if(no_of_rows.current.value !== ''){
      var total_bus_seats = seats_per_row * no_of_rows.current.value;
      if(lastseat.current.value === 'true'){
        total_bus_seats += 1;
        total_seats.current.value = total_bus_seats;
      }else{
        total_seats.current.value = total_bus_seats;
      }
    }
  }

  const handleImage = (e) =>{
    var reader = new FileReader(); 
    reader.onload = (e) => {
      setImage(e.target.result);
    };
    reader.readAsDataURL(e.target.files[0]);
  }

  const handleAssignedFacilitiesChange = (e) => {
    allowedFacilities.splice(0, allowedFacilities.length);
    var options = e;
    if(options !== undefined || options !== null){
      for (var i = 0; i < options.length; i++) {
        allowedFacilities.push(options[i]);
      }
    }
    setAllowedFacilities([...allowedFacilities]);
  }

  const editBusHandler = async(e) => {
  	e.preventDefault();
    setLoading(true);
    try {
      const apiResponse = await updateBus( {
        "bus":{
          "id": bus.Bus.id,
          "uuid": bus.Bus.uuid,
          "regNo": reg_no.current.value,
          "engineNo": engine_no.current.value,
          "modelNo": model_no.current.value,
          "chasisNo": chasis_no.current.value,
          "layout": layout.current.value,
          "noOfRows": no_of_rows.current.value,
          "totalSeats": total_seats.current.value,
          "lastseat": lastseat.current.value,
          "owner": owner.current.value,
          "company": company.current.value,
          "allowedTime": bus.Bus.allowedTime,
          "image": image,
          "status": status.current.value,
          "createdBy": bus.Bus.createdBy,
          "updatedBy": userId,
          "deleted": bus.Bus.deleted,
          "busTypeId": bus_type_id.current.value,
          "partnerId": partner_id.current.value,
        },
        "facilities": allowedFacilities
      }, userId);
      const { success, errors = [] } = apiResponse?.data;
      if(success){
        alertify.success("Record updated successfully");
        props.history.push("/bus/list");
      } else{
        alertify.error(errors[0].errorMsg);
      }
    } catch (error) {
      alertify.error(error.message);
    } finally {
      setLoading(false);
    }
  }
  return ( 
    loading ? <LoaderScreen /> :   
    <Row>
      <Col md={12}>
        <Card>
          <CardHeader title="Edit Bus"/>
          <CardBody>
            <div className="mt-5">
              <Form onSubmit={editBusHandler}>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Bus Type</Form.Label>
                    <Form.Control as="select" ref={bus_type_id} onChange={handleBusTypeChange} value={fields.bus_type_id}>
                      <option key='' value=''> Select Bus Type </option>
                      {busTypes.map((busType, key) => (
                        <option key={busType.uuid} value={busType.uuid}>{busType.name}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Status</Form.Label>
                    <Form.Control as="select" ref={status} onChange={handleChange} value={fields.status}>
                      <option value="true">Active</option>
                      <option value="false">In-Active</option>                
                    </Form.Control>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Reg No</Form.Label>
                    <Form.Control ref={reg_no} defaultValue={bus?.Bus?.regNo} placeholder="Enter Reg No" />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Engine No</Form.Label>
                    <Form.Control ref={engine_no} defaultValue={bus?.Bus?.engineNo} placeholder="Enter Engine No" />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Model No</Form.Label>
                    <Form.Control ref={model_no} defaultValue={bus?.Bus?.modelNo} placeholder="Enter Model No" />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Chasis No</Form.Label>
                    <Form.Control ref={chasis_no} defaultValue={bus?.Bus?.chasisNo} placeholder="Enter Chasis No" />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Owner</Form.Label>
                    <Form.Control ref={owner} defaultValue={bus?.Bus?.owner} placeholder="Enter owner" />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Company</Form.Label>
                    <Form.Control ref={company} defaultValue={bus?.Bus?.company} placeholder="Enter company" />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Layout</Form.Label>
                    <Form.Control as="select" ref={layout} onChange={handleLayoutChange} value={fields.layout}>
                      <option>1/1</option>
                      <option>1/2</option>
                      <option>1/3</option>
                      <option>1/2</option>
                      <option>1/4</option>
                      <option>2/1</option>
                      <option>2/2</option>
                      <option>2/3</option>
                      <option>2/4</option>
                      <option>3/1</option>
                      <option>3/2</option>
                      <option>3/3</option>
                      <option>3/4</option>
                      <option>4/1</option>
                      <option>4/2</option>
                      <option>4/3</option>
                      <option>4/4</option>
                                    
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>No Of Rows</Form.Label>
                    <Form.Control ref={no_of_rows} defaultValue={bus?.Bus?.noOfRows} onChange={handleTotalSeats} placeholder="Enter no of rows" />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Last Seat</Form.Label>
                    <Form.Control as="select" ref={lastseat} onChange={handleLastseatChange} value={fields.lastseat}>
                      <option value="false">No</option>
                      <option value="true">Yes</option>                
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Total Seats</Form.Label>
                    <Form.Control ref={total_seats} defaultValue={bus?.Bus?.totalSeats} disabled />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Partner</Form.Label>
                    <Form.Control as="select" ref={partner_id} value={fields.partner_id} onChange={handleOnPartnerChange}>
                      {partners.map(function(partner, key) {
                        return <option key={key} value={partner.uuid}>{partner.name}</option>
                      })}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Image</Form.Label>
                    <Row>
                      <Col><Form.Control type="file" onChange={handleImage} /></Col>
                      <Col>{image? <img width='100' src={image} alt=""/> : ''}</Col>
                    </Row>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Available Facilities</Form.Label>
                    <Select ref={selectedFacilities} onFocus={focus} isMulti isClearable isSearchable onInputChange={onInputChange} name="facilities" options={facilities}
                      menuIsOpen={menuIsOpen} onChange={handleAssignedFacilitiesChange} value={allowedFacilities}/>
                  </Form.Group>
                  <Form.Group as={Col}></Form.Group>
                </Form.Row>                
                <Button variant="primary" className="float-right" type="submit">Save</Button>
              </Form>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}