import React, { useEffect, useState, useRef } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import alertify from "alertifyjs";
import { Card, CardBody, CardHeader } from "../../../_metronic/_partials/controls";
import { useSelector } from "react-redux";
import { LoaderScreen } from '../../modules/Loader';
import { getBusTypeDetails, updateBusTypeDetails } from "../../../app/sagas/busManagement";

export function EditBusType(props) {

	const { userId } = useSelector((state) => state.Auth.user);
	const [loading, setLoading] = useState(true);
	const id = props.match.params.id;
  
	const initialState = {
		type: '',
		status: '',
	};

	const [type, setType] = useState({});
	const [fields, setFields] = useState(initialState);
  	const name = useRef();
  	const status = useRef();

	useEffect(() => {
		const fetchData = async () => {
			try {
				const apiResponse = await getBusTypeDetails( id );
				const { success, data = {}, errors = [] } = apiResponse?.data;
				if(success){
				setFields({
					status: data.status
				});
				setType(data);
				} else{
				alertify.error(errors[0].errorMsg);
				}
			} catch (error) {
				alertify.error(error.message);
			} finally {
				setLoading(false);
			}	
		};
		fetchData();
	}, []);

	const handleChange = (e) => {
		setFields({status: e.target.value});
	}

  	const editBusTypeHandler = async(e) => {
		e.preventDefault();
		setLoading(true);
		try {
			const apiResponse = await updateBusTypeDetails({
				"busType":{
					"id": type.id,
					"uuid": type.uuid,
					"name": name.current.value,
					"status": status.current.value,
					"createdBy": type.createdBy,
					"updatedBy": type.updatedBy,
					"deleted": type.deleted
				}
			}, userId);
			const { success, errors = [] } = apiResponse?.data;
			if(success){
				alertify.success("Record updated successfully");
				props.history.push("/bus-type/list");
			} else{
				alertify.error(errors[0].errorMsg);
			}
		} catch (error) {
			alertify.error(error.message);
		} finally {
			setLoading(false);
		}
	}

  return (
	loading ? <LoaderScreen />:
	<Row>
		<Col md={12}>
			<Card>
			<CardHeader title="Edit Bus Type"/>
			<CardBody>        
				<div className="mt-5">
					<Form onSubmit={editBusTypeHandler}>
					<Form.Row>
						<Form.Group as={Col}>
							<Form.Label>Name</Form.Label>
							<Form.Control ref={name} defaultValue={type.name} placeholder="Enter name" />
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>Status</Form.Label>
							<Form.Control as="select" ref={status} onChange={handleChange} value={fields.status}>
							<option value="true">Active</option>
							<option value="false">In-Active</option>
							</Form.Control>
						</Form.Group>
					</Form.Row>
					<Button variant="primary" className="float-right" type="submit">
						Save
					</Button>
					</Form>
				</div>
			</CardBody>
			</Card>
		</Col>
	</Row>
  );
}