import axios from "../../config/axiosConfig";
import { formatRequest, } from "./common";

const { REACT_APP_API_VERSION } = process.env;

export function createUser(data, userId) {
  return axios(
    formatRequest(
      `/api/app/${REACT_APP_API_VERSION}/user/create/${userId}`,
      "post",
      data,
      true,
      true,
      1000 * 60 * 5 // 5 minutes as per 7323
    )
  );
}
export function getUsers(offset, limit) {
  return axios(
    formatRequest(
      `/api/app/${REACT_APP_API_VERSION}/user/all/${offset}/${limit}`,
      "get",
      null,
      true,
      true
    )
  );
}
export function getUser(userId) {
  return axios(
    formatRequest(
      `/api/app/${REACT_APP_API_VERSION}/user/${userId}`,
      "get",
      null,
      true,
      true
    )
  );
}
export function updateUser(data, userId) {
  return axios(
    formatRequest(
      `/api/app/${REACT_APP_API_VERSION}/user/${userId}/edit`,
      "put",
      data,
      true,
      true
    )
  );
}
export function removeUser(busType, userId) {
  return axios(
    formatRequest(
      `/api/app/${REACT_APP_API_VERSION}/user/${userId}/${busType}`,
      "delete",
      undefined,
      true,
      true
    )
  );
}
export function changeAccountStatus(userId, status, accountId) {
  return axios(
    formatRequest(
      `/api/app/${REACT_APP_API_VERSION}/user/${userId}/change-status/${status}/${accountId}`,
      "put",
      null,
      true,
      true
    )
  );
}

export function createOperator(data, userId) {
  return axios(
    formatRequest(
      `/api/app/${REACT_APP_API_VERSION}/bus/operator/${userId}`,
      "post",
      data,
      true,
      true,
      1000 * 60 * 5 // 5 minutes as per 7323
    )
  );
}
export function getOperators(offset, limit) {
  return axios(
    formatRequest(
      `/api/app/${REACT_APP_API_VERSION}/bus/operator/all/${offset}/${limit}`,
      "get",
      null,
      true,
      true
    )
  );
}
export function removeOperator(operator, userId) {
  return axios(
    formatRequest(
      `/api/app/${REACT_APP_API_VERSION}/bus/operator/${userId}/${operator}`,
      "delete",
      undefined,
      true,
      true
    )
  );
}
export function getOperator(operator) {
  return axios(
    formatRequest(
      `/api/app/${REACT_APP_API_VERSION}/bus/operator/${operator}`,
      "get",
      null,
      true,
      true
    )
  );
}
export function updateOperator(data, userId) {
  return axios(
    formatRequest(
      `/api/app/${REACT_APP_API_VERSION}/bus/operator/${userId}/edit`,
      "put",
      data,
      true,
      true
    )
  );
}

export function createAgent(data, userId) {
  return axios(
    formatRequest(
      `/api/app/${REACT_APP_API_VERSION}/bus/agent/${userId}`,
      "post",
      data,
      true,
      true,
      1000 * 60 * 5 // 5 minutes as per 7323
    )
  );
}
export function getAgents(base) {
  return axios(
    formatRequest(
      `/api/app/${REACT_APP_API_VERSION}/bus/${base}`,
      "get",
      null,
      true,
      true
    )
  );
}

export function removeAgent(agentId, userId) {
  return axios(
    formatRequest(
      `/api/app/${REACT_APP_API_VERSION}/bus/agent/${userId}/${agentId}`,
      "delete",
      undefined,
      true,
      true
    )
  );
}
export function getAgent(agentId) {
  return axios(
    formatRequest(
      `/api/app/${REACT_APP_API_VERSION}/bus/agent/${agentId}`,
      "get",
      null,
      true,
      true
    )
  );
}
export function updateAgent(data, userId) {
  return axios(
    formatRequest(
      `/api/app/${REACT_APP_API_VERSION}/bus/agent/${userId}/edit`,
      "put",
      data,
      true,
      true
    )
  );
}