import * as actionTypes from "./actionTypes";

const cancelErrorAction = () => ({ type: actionTypes.ERROR_CANCELED });

const completeLoadingAction = () => ({ type: actionTypes.LOADING_COMPLETED });

const requestLoadingAction = (apiType, actionName) => ({
  type: actionTypes.LOADING_RECEIVED,
  apiType,
  actionName,
});

const requestLogin = ( user, password, saveUserDataInCache = false, ) => ({
  type: actionTypes.REQUEST_LOGIN,
  user,
  password,
  saveUserDataInCache,
});

const requestSetPassword = (emailId, defaultPassword, password) => ({
  type: actionTypes.REQUEST_SET_PASSWORD,
  emailId,
  defaultPassword,
  password,
});

const requestChangePassword = (emailId) => ({
  type: actionTypes.REQUEST_CHANGE_PASSWORD,
  emailId
});

const destroySession = () => ({
    type: actionTypes.DESTROY_SESSION,
});

const updateAuthToken = () => ({
    type: actionTypes.REQUEST_REFRESH_JWT_TOKEN,
});

const saveCity = (data, userId) => ({
  type: actionTypes.SAVE_CITY,
  data,
  userId
});
const updateCity = (data) => ({
  type: actionTypes.UPDATE_CITY,
  data
});
const fetchCities = (offset, limit) => ({
  type: actionTypes.FETCH_CITY_LIST,
  offset,
  limit,
});
const fetchCity = (cityId) => ({
  type: actionTypes.FETCH_CITY,
  cityId,
});
const removeCity = (cityId, userId) => ({
  type: actionTypes.REMOVE_CITY,
  cityId,
  userId
});

export const actions = {
    auth: {
      requestLogin,
      updateAuthToken,
    },
    error: {
      cancelErrorAction,
    },
    loading: {
      requestLoadingAction,
      completeLoadingAction,
    },
    changePassword: {
      requestChangePassword,
      requestSetPassword,
    },
    session: {
      destroySession,
    },
    routesManagement:{
      city:{
        saveCity,
        updateCity,
        fetchCities,
        fetchCity,
        removeCity
      }
    },
};

export const events = {
    auth: {
      REQUEST_REFRESH_JWT_TOKEN: actionTypes.REQUEST_REFRESH_JWT_TOKEN,
      RECEIVED_REFRESH_JWT_TOKEN: actionTypes.RECEIVED_REFRESH_JWT_TOKEN,
      FAILED_REFRESH_JWT_TOKEN: actionTypes.FAILED_REFRESH_JWT_TOKEN,
    },
    login: {
      REQUEST: actionTypes.REQUEST_LOGIN,
      RECEIVED: actionTypes.RECEIVED_LOGIN,
      FAILED: actionTypes.FAILED_LOGIN,
    },
    error: {
      RECEIVED: actionTypes.ERROR_RECEIVED,
      CANCELED: actionTypes.ERROR_CANCELED,
    },
    loading: {
      RECEIVED: actionTypes.LOADING_RECEIVED,
      COMPLETED: actionTypes.LOADING_COMPLETED,
    },
    changePassword: {
      REQUEST: actionTypes.REQUEST_CHANGE_PASSWORD,
      RECEIVED: actionTypes.RECEIVED_CHANGE_PASSWORD,
      FAILED: actionTypes.FAILED_CHANGE_PASSWORD,
    },
    session: {
      DESTROY_SESSION: actionTypes.DESTROY_SESSION,
    },
    routesManagement:{
      city:{
        SAVE_CITY: actionTypes.SAVE_CITY,
        SAVE_CITY_SUCCESS: actionTypes.SAVE_CITY_SUCCESS,
        SAVE_CITY_FAILED: actionTypes.SAVE_CITY_FAILED,
        UPDATE_CITY: actionTypes.UPDATE_CITY,
        UPDATE_CITY_RECEIVED: actionTypes.UPDATE_CITY_RECEIVED,
        UPDATE_CITY_FAILED: actionTypes.UPDATE_CITY_FAILED,
        FETCH_CITY: actionTypes.FETCH_CITY,
        FETCH_CITY_SUCCESS: actionTypes.FETCH_CITY_SUCCESS,
        FETCH_CITY_FAILED: actionTypes.FETCH_CITY_FAILED,
        FETCH_CITY_LIST: actionTypes.FETCH_CITY_LIST,
        FETCH_CITY_LIST_SUCCESS: actionTypes.FETCH_CITY_LIST_SUCCESS,
        FETCH_CITY_LIST_FAILED: actionTypes.FETCH_CITY_LIST_FAILED,
        REMOVE_CITY: actionTypes.REMOVE_CITY,
        REMOVE_CITY_SUCCESS: actionTypes.REMOVE_CITY_SUCCESS,
        REMOVE_CITY_FAILED: actionTypes.REMOVE_CITY_FAILED,
      }
    }
};