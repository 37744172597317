export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_EXCEPTION = "LOGIN_EXCEPTION";
export const TOKEN_REFRESH_FAIL = "TOKEN_REFRESH_FAIL";
export const TOKEN_REFRESH_EXCEPTION = "TOKEN_REFRESH_EXCEPTION";
export const PASSWORD_CHANGE_REQUEST_FAIL = "PASSWORD_CHANGE_REQUEST_FAIL";
export const PASSWORD_CHANGE_REQUEST_EXCEPTION = "PASSWORD_CHANGE_REQUEST_EXCEPTION";
export const PASSWORD_CHANGE_APPROVAL_FAIL = "PASSWORD_CHANGE_APPROVAL_FAIL";
export const PASSWORD_CHANGE_APPROVAL_EXCEPTION = "PASSWORD_CHANGE_APPROVAL_EXCEPTION";
export const EMAIL_CHANGE_REQUEST_FAIL = "EMAIL_CHANGE_REQUEST_FAIL";
export const EMAIL_CHANGE_REQUEST_EXCEPTION = "EMAIL_CHANGE_REQUEST_EXCEPTION";
export const EMAIL_CHANGE_APPROVAL_FAIL = "EMAIL_CHANGE_APPROVAL_FAIL";
export const EMAIL_CHANGE_APPROVAL_EXCEPTION = "EMAIL_CHANGE_APPROVAL_EXCEPTION";
export const EMAIL_ID_ALREADY_REGISTERED = "EMAIL_ID_ALREADY_REGISTERED";
export const ATTEMPTS_EXCEEDED = "ATTEMPTS_EXCEEDED";
export const PASSWORD_RESET_ATTEMPTS_EXCEEDED = "PASSWORD_RESET_ATTEMPTS_EXCEEDED";
export const GENERIC_ERROR = "GENERIC_ERROR";
export const RESEND_TIME_LIMIT_EXCEEDED = "RESEND_TIME_LIMIT_EXCEEDED";
export const INVALID_REFERRAL_CODE = "INVALID_REFERRAL_CODE";
export const USER_NOT_FOUND = "USER_NOT_FOUND";
