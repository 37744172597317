import React, { } from "react";
import {FaqList} from "./FaqList";
import {Link} from "react-router-dom";

import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";

export function Faqs() {
  return (
  	<Card>
      <CardHeader title="Faq List">
        <CardHeaderToolbar>
           
          <Link to="/faq/create" className="btn btn-primary">Add New</Link>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        
        <div className="mt-5">
              <FaqList />
        </div>

      </CardBody>
    </Card>

  );
}