import React, { } from "react";
import {UserList} from "./UserList";

import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";

export function Users() {
  return (
  	<Card>
      <CardHeader title="User List">
        <CardHeaderToolbar>
           
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        
        <div className="mt-5">
              <UserList />
        </div>

      </CardBody>
    </Card>

  );
}