import React, { } from "react";
import {BusTypeList} from "./BusTypeList";
import {Link} from "react-router-dom";

import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";

export function BusTypes() {
  return (
  	<Card>
      <CardHeader title="Bus Type List">
        <CardHeaderToolbar>           
          <Link to="/bus-type/create" className="btn btn-primary">Add New</Link>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>        
        <div className="mt-5">
          <BusTypeList />
        </div>
      </CardBody>
    </Card>
  );
}