import React, {useEffect, useState} from "react";
import $ from "jquery";
import {Link} from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router';
import { toAbsoluteUrl } from "../../../_helpers";
import SVG from "react-inlinesvg";
import alertify from "alertifyjs";
import { useSelector } from "react-redux";
import { LoaderScreen } from '../../modules/Loader';
import { getBusList, removeBus } from "../../../app/sagas/busManagement";
import { getUser } from "../../../app/sagas/userManagement";

export function BusList(){

	const [loading, setLoading] = useState(true);
  	const { userId, role } = useSelector((state) => state.Auth.user);

	const [buses, setBuses] = useState([]);
	const [bus, setBus] = useState(null);
	const [deleting, setDeleting] = useState(false);
	const [show, setShow] = useState(false);
	const history = useHistory();
	const [offset, setOffset] = useState(0);
	const [pageLimit, setPageLimit] = useState(100);
	const [totalPages, setTotalPages] = useState(0);

	const setCurrentBus = (id) => {
		if(role !== "super"){
			alertify.error("Action not permitted");
		} else{
			setBus(id);
			setShow(true);
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				if(role === "super"){
					const apiResponse = await getBusList(`bus/all/${offset}/${pageLimit}`);
					const { data = [], success } = apiResponse?.data;
					if (success) {
						setBuses(data);
					}
				} else if(role === "admin"){
					const userResponse = await getUser(userId);
					const { success } = userResponse?.data;
					if (success) {
						localStorage.setItem("busOperator", data.user.busOperator);
						const apiResponse = await getBusList(`all/${data.user.busOperator}/operator/${offset}/${pageLimit}`);
						const { data = [], success } = apiResponse?.data;
						if (success) {
							setBuses(data);
						}
					}			
				}
				
			} catch (error) {
				alertify.error(error.message);
			} finally{
				if(buses.length > 0){
					$("#bus_table").DataTable({responsive: true, stateSave: true});			
					setTotalPages(buses.length);
					setOffset(buses.length + 1);
					setPageLimit(buses.length + 100);
				}
				setLoading(false)
			}
		};
		fetchData();
	}, []);

  	const deleteBus = async() => {
		try {
			setDeleting(true)
			const apiResponse = await removeBus(bus, userId);
			const { success, errors = [] } = apiResponse?.data;
			if(success){
			  alertify.success("Record removed successfully");
			  history.go(0);
			} else{
			  alertify.error(errors[0].errorMsg);
			}
		} catch (error) {
			alertify.error(error.message);
		} finally {
			setShow(false);
			setDeleting(false);
		}
  	}

	return (
		loading ? <LoaderScreen /> :
		<>
			<table id="bus_table" className="sharearide_datatable">
				<thead>
					<tr>
						<th>ID</th>
						<th>Reg No</th>
						<th>Engine No</th>
						<th>Model No</th>
						<th>Chasis</th>
						<th>Layout</th>
						<th>Total Seats</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{buses.length > 0 ? (
						buses.map((res, index) => (
							<tr key={index}>
								<td>{res?.Bus?.id}</td>
								<td>{res?.Bus?.regNo}</td>
								<td>{res?.Bus?.engineNo}</td>
								<td>{res?.Bus?.modelNo}</td>
								<td>{res?.Bus?.chasisNo}</td>
								<td>{res?.Bus?.layout}</td>
								<td>{res?.Bus?.totalSeats}</td>
								{role === "admin" ?
									<td>
										<Link to={`/bus/view/${res?.Bus?.uuid}`} className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
											<span className="svg-icon svg-icon-md svg-icon-primary">
												<SVG src={toAbsoluteUrl("/media/svg/icons/General/Visible.svg")} />
											</span>
										</Link>
									</td>:	
									<td>
										<Link to={`/bus/edit/${res?.Bus?.uuid}`} className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
											<span className="svg-icon svg-icon-md svg-icon-primary">
												<SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}/>
											</span>
										</Link>
										<a onClick={() => setCurrentBus(res?.Bus?.uuid)} key={index} className="btn btn-icon btn-light btn-hover-danger btn-sm">
											<span className="svg-icon svg-icon-md svg-icon-danger">
												<SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
											</span>
										</a>
									</td>
								}		            
							</tr>
						))
					) : (
						<tr>
							<td colSpan={3}>No Bus</td>
						</tr>
					)}
				</tbody>
		  	</table>
			<Modal show={show} onHide={() => setShow(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Delete Bus</Modal.Title>
				</Modal.Header>
				<Modal.Body>Are you sure you want to Delete?</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShow(false)} disabled={ deleting }> Cancel </Button>
					<Button variant="primary" onClick={deleteBus} disabled={ deleting }>
						<span>Yes</span> {deleting && <span className="ml-2 spinner spinner-white"></span>}&nbsp;&nbsp;
					</Button>
				</Modal.Footer>
			</Modal>
	    </>
	)
}