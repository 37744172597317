import React, { } from "react";

export function Unauthorized(props) {
  return (
    <>
      <div className="wrapper" >
        <h1>Hmm.</h1>
        <p>It seems that you're lost in a perpetual black hole. Let us help guide you out and get you back home.</p>
      </div>
    </>
  );
}