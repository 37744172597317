import { PURGE } from "redux-persist";
import { events } from "../actions";

const INITIAL_STATE = { error: { status: false } };

/** TODO: MANAGE FAILURE WITH ALERTS */
export default (state = { error: { status: false } }, response) => {
  switch (response.type) {
    case events.error.RECEIVED:
      return {
        ...state,
        error: {
          status: true,
          message: response.message,
        },
      };
    case events.error.CANCELED:
      return {
        ...state,
        error: {
          status: false,
        },
      };
    case PURGE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
